/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner, Button } from '@cfacorp/cowponents';
import { Column, Radio, Card, Row, Icon, Overlay, ButtonSpinner, Totals } from '../index';

class VaultedCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cardToDelete: {} };
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
  }

  handleDeleteCard(card) {
    this.setState({ cardToDelete: card });
    this.props.deleteCard(card);
  }

  render() {
    const {
      vaultedCards,
      accountSelected,
      selectedAccount,
      openModal,
      deleteCardLoading,
      vaultedCardsLoading,
      addCardLoading,
      submitOrder,
      isSubmitPaymentLoading,
      totals,
      deliveryTipIsDisplayed,
    } = this.props;
    const buttonText = vaultedCards.length > 0 ? 'Add another credit card' : 'Add a credit card';
    const { cardToDelete } = this.state;
    return (
      <StyledVaultedCards>
        { addCardLoading && <Overlay /> }
        { vaultedCardsLoading ? <Spinner /> :
        <React.Fragment>
          <div className="charge-info">Your card will be charged for the full amount of the order on the pickup or delivery date.</div>
          <Totals
            className="payment-totals"
            subTotal={totals.subTotal}
            tax={totals.tax}
            taxAndTotal={totals.taxAndTotal}
            deliveryTipIsDisplayed={deliveryTipIsDisplayed}
            deliveryTipAmount={totals.deliveryTipAmount}
          />
          { vaultedCards.length > 0 ? vaultedCards.map(card => (
            <Row className="card-row" key={card.id}>
              <Column xs="8" sm="5">
                <label className="label" htmlFor={card.id}>
                  <Radio
                    id={card.id}
                    checked={selectedAccount.id === card.id}
                    onChange={() => accountSelected(card)}
                    disabled={card.isExpired}
                  />
                  <Column className="card-icon"><Card expired={card.isExpired} card={card} /></Column>
                </label>
              </Column>
              <Column xs="4" sm="7">
                { deleteCardLoading && cardToDelete.id === card.id ? <Spinner /> :
                <Button variant="basic" className="remove" onClick={() => this.handleDeleteCard(card)}>
                    Remove
                </Button> }
              </Column>
            </Row>
            )) :
          <div className="no-cards">
            <Icon>payment</Icon>
            <div>No payment methods found, please add a credit card.</div>
          </div> }
          <Button variant="basic" data-cy="newCardBtn" className="new-card" onClick={openModal}>
            {buttonText}
          </Button>
          { isSubmitPaymentLoading ? <ButtonSpinner className="confirm-payment" width="167px" /> :
            <Button variant="customer" className="confirm-payment" disabled={!selectedAccount.id} onClick={submitOrder}>
              Place your order now
            </Button> }
        </React.Fragment> }
      </StyledVaultedCards>
    );
  }
}

export const StyledVaultedCards = styled.div`
  margin: 40px 0;
  & .label {
    color: ${props => props.theme.text};
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
  }
  & .remove button {
    text-align: right;
  }
  & .card-row {
    display: flex;
    align-items: center;
  }
  & .new-card, .confirm-payment {
    display: inherit;
    margin: 10px 0;
  }
  & .remove, .new-card {
    color: ${props => props.theme.tertiary};
  }
  & .remove:hover, .new-card:hover {
    text-decoration: underline;
  }
  & .card-icon {
    margin-left: 12px;
  }
  & .no-cards {
    color: ${props => props.theme.text};
    text-align: center;
    margin: 50px;
  }
  & .no-cards svg {
    width: 35px;
    margin: 10px;
    fill: ${props => props.theme.text};
  }
  & .loading {
    width: 162px;
  }
  & .charge-info {
    color: ${props => props.theme.text};
    font-size: 17px;
    font-weight: 400;
    margin: 30px 0;
  }
  & .payment-totals div {
    width: 50%;
    text-align: left;
  }
  @media (max-width: 500px) {
    & .confirm-payment {
      width: 100%;
    }
  }
`;

VaultedCards.propTypes = {
  vaultedCards: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])),
  accountSelected: PropTypes.func.isRequired,
  selectedAccount: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.bool,
    PropTypes.object,
  ])).isRequired,
  openModal: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
  deleteCardLoading: PropTypes.bool,
  addCardLoading: PropTypes.bool,
  vaultedCardsLoading: PropTypes.bool,
  submitOrder: PropTypes.func.isRequired,
  isSubmitPaymentLoading: PropTypes.bool.isRequired,
  totals: PropTypes.objectOf(PropTypes.string).isRequired,
  deliveryTipIsDisplayed: PropTypes.bool,
};

VaultedCards.defaultProps = {
  vaultedCards: [],
  deleteCardLoading: false,
  addCardLoading: false,
  vaultedCardsLoading: false,
  deliveryTipIsDisplayed: false,
};

export default VaultedCards;
