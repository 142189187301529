import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const Error = ({ text, className }) =>
  (
    <StyledError className={className}>
      <Icon className="icon">error</Icon>
      {text}
    </StyledError>
  );

const StyledError = styled.p`
  color: ${props => props.theme.text};
    
  & .icon {
    height: 12px;
    width: 12px;
    margin: 0 2px;
  }
`;

Error.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

Error.defaultProps = {
  className: '',
  text: '',
};

export default Error;
