import PropTypes from 'prop-types';
import styled from 'styled-components';
import Column from '../Column/Column';
import Row from '../Row/Row';
import SectionDivider from '../Section/SectionDivider';
import Modifiers from '../Modifiers/Modifiers';
import { formatItemName, formatPrice } from '../../util/format';

function OrderSummary({
  cartItems,
}) {
  return (
    <StyledOrderSummary>
      <SectionDivider />
      <Row>
        <Column className="name small-title" xs="7">Item Name</Column>
        <Column className="small-title" xs="2">Quantity</Column>
        <Column className="small-title" xs="3">Price</Column>
      </Row>
      {cartItems.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${item.tag}${index}`}>
          <Row>
            <Column data-cy={`${item.tag}-name`} className="name item" xs="7">{formatItemName(item.name)}</Column>
            <Column data-cy={`${item.tag}-quantity`} className="item" xs="2">{item.quantity}</Column>
            <Column data-cy={`${item.tag}-price`} className="item" xs="3">
              {formatPrice(item.itemPrice * item.quantity)}
            </Column>
          </Row>
          <Modifiers className="modifiers" item={item} />
        </div>
      ))}
    </StyledOrderSummary>
  );
}

const StyledOrderSummary = styled.div`
  color: ${props => props.theme.text};
  & .small-title, .item {
    text-align: right;
    margin: 10px 0;
  }
  & .name {
    text-align: left !important;
  }
  .item {
    font-size: 15px;
    font-weight: 200;
    letter-spacing: -0.5px;
    line-height: 26px;
  }
`;

OrderSummary.propTypes = {
  cartItems: PropTypes.arrayOf(PropTypes.object),
};

OrderSummary.defaultProps = {
  cartItems: [],
};

export default OrderSummary;
