import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer, { combinedActionCreators } from './reducers';
import rootEpic from './epics';

const epicMiddleware = createEpicMiddleware(rootEpic, {
  dependencies: { fetch },
});
const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  actionCreators: combinedActionCreators,
});

const configureStore = (history) => {
  const store = createStore(
    connectRouter(history)(rootReducer),
    composeEnhancers(applyMiddleware(epicMiddleware, routerMiddleware(history))),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
      module.hot.accept('./epics', () => epicMiddleware.replaceEpic(rootEpic));
    }
  }

  return store;
};

export default configureStore;
