/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box, Flex } from '@cfacorp/cowponents';
import DeliveryTipsPercentageButton from './DeliveryTipsPercentageButton';
import DeliveryTipsCustomAmount from './DeliveryTipsCustomAmount';

const DeliveryTips = props => {
  const [isSelect, setIsSelect] = useState(false);
  const checkbox1 = React.createRef();
  const checkbox2 = React.createRef();
  const checkbox3 = React.createRef();
  const checkbox4 = React.createRef();
  const { subTotal, selectedPercentage, selectedCustomTipValue, addDeliveryTip } = props;

  const lesThanMinForPecentage = subTotal < 25;
  const onClickHandler = (e) => {
    addDeliveryTip('tipPercentOfSale', e.target.value);
    setIsSelect(false);
  };

  const onBlurHandler = e => {
    e.preventDefault();
    if (e.target.value !== '') {
      addDeliveryTip('tipAmount', e.target.value);
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
    checkbox1.current.checked = false;
    checkbox2.current.checked = false;
    checkbox3.current.checked = false;
    checkbox4.current.checked = false;
  };


  return (
    <StyledDeliveryTips className="styled-delivery-tips">
    <div className="delivery-tip-intro">Would you like to tip your driver?</div>
    <Flex display="flex" flexWrap="wrap">
      <Box width={[1 / 2, 1 / 6]}>
        <DeliveryTipsPercentageButton
          tipPercentageButtonId="p10"
          value="10"
          onClickHandler={onClickHandler}
          disabled={lesThanMinForPecentage}
          ref={checkbox1}
          checked={selectedPercentage === 10}
          className="percentage-button"
        >
            10%
        </DeliveryTipsPercentageButton>
      </Box>
      <Box width={[1 / 2, 1 / 6]}>
        <DeliveryTipsPercentageButton
          tipPercentageButtonId="p15"
          value="15"
          onClickHandler={onClickHandler}
          disabled={lesThanMinForPecentage}
          ref={checkbox2}
          checked={selectedPercentage === 15}
          className="percentage-button"
        >
          15%
        </DeliveryTipsPercentageButton>
      </Box>
      <Box width={[1 / 2, 1 / 6]}>
        <DeliveryTipsPercentageButton
          tipPercentageButtonId="p20"
          value="20"
          onClickHandler={onClickHandler}
          disabled={lesThanMinForPecentage}
          ref={checkbox3}
          checked={selectedPercentage === 20}
          className="percentage-button"
        >
          20%
        </DeliveryTipsPercentageButton>
      </Box>
      <Box width={[1 / 2, 1 / 6]}>
        <DeliveryTipsPercentageButton
          tipPercentageButtonId="p25"
          value="25"
          onClickHandler={onClickHandler}
          disabled={lesThanMinForPecentage}
          ref={checkbox4}
          checked={selectedPercentage === 25}
          className="percentage-button"
        >
          25%
        </DeliveryTipsPercentageButton>
      </Box>
      <Box width={[1, 2 / 6]}>
        <DeliveryTipsCustomAmount
          isSelect={isSelect}
          onBlurHandler={onBlurHandler}
          selectedCustomTipValue={selectedCustomTipValue}
        />
      </Box>
    </Flex>
    </StyledDeliveryTips>
  );
};

const StyledDeliveryTips = styled('div')`
    & .delivery-tip-intro {
      color: ${props => props.theme.text};
      font-size: 17px;
      font-weight: 400;
      margin: 30px 0;
    }
`;

DeliveryTips.propTypes = {
  subTotal: PropTypes.number,
  selectedPercentage: PropTypes.number,
  selectedCustomTipValue: PropTypes.number,
  addDeliveryTip: PropTypes.func,
};

DeliveryTips.defaultProps = {
  subTotal: 0,
  selectedPercentage: 0,
  selectedCustomTipValue: 0,
  addDeliveryTip: () => {},
};

export default DeliveryTips;
