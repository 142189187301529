import { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ChickenSpinner } from '@cfacorp/cowponents';
import {
  Section,
  SectionHeader,
  OrderSummary,
  Totals,
  EventSummary,
} from '../components';
import { selectGuestDetails, selectShowPasswordPrompt, actions as guestActions } from '../reducers/guest';
import {
  actions as paymentActions,
} from '../reducers/payment';
import {
  selectDestination,
  selectEventDetails,
  selectCartItems,
  selectDestinationDetails,
  selectPaymentSubmitted,
  actions as orderActions,
  selectFormattedTotals,
  selectDeliveryTipIsDisplayed,
  selectDeliveryTipLoading,
  selectSelectedPercentageTipValue,
  selectSelectedCustomTipValue,
  selectSubTotalAmount,
} from '../reducers/order';
import { selectAppLoading } from '../reducers';
import { getHash } from '../util/location';
import DeliveryTips from '../components/DeliveryTips/DeliveryTips';

export class IntroSummary extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      guestDetails,
      destination,
      eventDetails,
      destinationDetails,
      formattedTotals,
      cartItems,
      showPasswordPrompt,
      loading,
      stepCompleted,
      addDeliveryTip,
      deliveryTipIsDisplayed,
      deliveryTipLoading,
      selectedPercentage,
      selectedCustomTipValue,
      subTotalAmount,
    } = this.props;

    const renderButton = () => {
      const location = showPasswordPrompt ? `/guestLogin/#id_token=${getHash().id_token}` : `/payment/#id_token=${getHash().id_token}`;
      const buttonText = showPasswordPrompt ? 'Continue to Create Account' : 'Continue to Payment';
      return (
        <div className="continue">
          <NavLink to={location}>
            <Button
              className="button-text"
              variant="customer"
              onClick={() => stepCompleted(1)}
            >
              {buttonText}
            </Button>
          </NavLink>
        </div>
      );
    };

    return (
      <StyledIntroSummary>
        <Section>
          {loading ? (
            <ChickenSpinner />
          ) : (
            <>
              <SectionHeader>Order summary</SectionHeader>
              <EventSummary
                destination={destination}
                guestDetails={guestDetails}
                eventDetails={eventDetails}
                destinationDetails={destinationDetails}
              />
              {deliveryTipIsDisplayed && (
                <>
                  <SectionHeader>Add Delivery Tip</SectionHeader>
                  <DeliveryTips
                    addDeliveryTip={addDeliveryTip}
                    subTotal={subTotalAmount}
                    selectedPercentage={selectedPercentage}
                    selectedCustomTipValue={selectedCustomTipValue}
                  />
                </>
              )}
              <OrderSummary cartItems={cartItems} />
              <Totals
                subTotal={formattedTotals.subTotal}
                tax={formattedTotals.tax}
                taxAndTotal={formattedTotals.taxAndTotal}
                deliveryTipIsDisplayed={deliveryTipIsDisplayed}
                deliveryTipAmount={formattedTotals.deliveryTipAmount}
                deliveryTipLoading={deliveryTipLoading}
              />
              {renderButton()}
            </>
          )}
        </Section>
      </StyledIntroSummary>
    );
  }
}

const StyledIntroSummary = styled.div`
  & .continue {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 500px) {
    & .continue button, .continue a {
      width: 100%;
    }
  }
`;

IntroSummary.propTypes = {
  destination: PropTypes.string.isRequired,
  guestDetails: PropTypes.objectOf(PropTypes.string),
  eventDetails: PropTypes.shape({
    time: PropTypes.string,
    guestCount: PropTypes.number,
    paperGoods: PropTypes.bool,
    specialInstructions: PropTypes.string,
    date: PropTypes.object,
  }),
  destinationDetails: PropTypes.objectOf(PropTypes.string),
  showPasswordPrompt: PropTypes.bool.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
  stepCompleted: PropTypes.func.isRequired,
  formattedTotals: PropTypes.objectOf(PropTypes.string),
  addDeliveryTip: PropTypes.func,
  deliveryTipIsDisplayed: PropTypes.bool,
  deliveryTipLoading: PropTypes.bool,
  selectedPercentage: PropTypes.number,
  selectedCustomTipValue: PropTypes.number,
  subTotalAmount: PropTypes.number,
};

IntroSummary.defaultProps = {
  guestDetails: {},
  destinationDetails: {},
  eventDetails: {},
  cartItems: [{}],
  formattedTotals: {},
  loading: false,
  addDeliveryTip: () => {},
  deliveryTipIsDisplayed: false,
  deliveryTipLoading: false,
  selectedPercentage: 0,
  selectedCustomTipValue: 0,
  subTotalAmount: 0,
};

function mapStateToProps(state) {
  return {
    guestDetails: selectGuestDetails(state),
    eventDetails: selectEventDetails(state),
    showPasswordPrompt: selectShowPasswordPrompt(state),
    destination: selectDestination(state),
    loading: selectAppLoading(state),
    cartItems: selectCartItems(state),
    destinationDetails: selectDestinationDetails(state),
    submitted: selectPaymentSubmitted(state),
    formattedTotals: selectFormattedTotals(state),
    deliveryTipIsDisplayed: selectDeliveryTipIsDisplayed(state),
    deliveryTipLoading: selectDeliveryTipLoading(state),
    selectedPercentage: selectSelectedPercentageTipValue(state),
    selectedCustomTipValue: selectSelectedCustomTipValue(state),
    subTotalAmount: selectSubTotalAmount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...guestActions, ...paymentActions, ...orderActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroSummary);
