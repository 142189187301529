import styled from 'styled-components';

const Section = styled.section`
  font-family: ${props => props.theme.fontFamily};
  padding: 0 5em 5em;
  max-width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
  
  @media (max-width: 600px) {
    padding: 0 2em 2em;
  }
`;

export default Section;

