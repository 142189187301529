import { v4 as uuidv4 } from 'uuid';
import publicIp from 'public-ip';
import { writeScriptTag } from './writeAndRemoveScriptTags';

// eslint-disable-next-line arrow-body-style
const generateSessionIdFromUuid = () => {
  return uuidv4();
};

// eslint-disable-next-line arrow-body-style
const getIpAddressViaLibrary = () => {
  return publicIp.v4();
};

const writeThreatMetrixScriptTag = ({ sessionId, ipAddress }) => {
  const orgId = 'gh5hqwlf';
  const url = `https://tmetrix.my.chick-fil-a.com/fp/tags.js?org_id=${orgId}&session_id=${sessionId}&input_ip_address=${ipAddress}`;

  return writeScriptTag(url);
};

const getValidatedSessionAndIp = async () => {
  const sessionId = generateSessionIdFromUuid();
  const ipAddress = await getIpAddressViaLibrary();
  await writeThreatMetrixScriptTag({ sessionId, ipAddress, writeScriptTag });
  return { sessionId, ipAddress };
};

export default getValidatedSessionAndIp;
