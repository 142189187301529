export const writeScriptTag = (src) =>
  new Promise((resolve) => {
    const tag = document.createElement('script');
    tag.setAttribute('src', src);
    tag.onload = resolve;
    document.body.appendChild(tag);
  });

export const removeScriptTag = (src) => {
  document
    .querySelectorAll(`script[src="${src}"]`)
    .forEach((node) => document.body.removeChild(node));
};

export default { writeScriptTag, removeScriptTag };
