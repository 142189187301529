import moment from 'moment';
import { contains } from 'ramda';
import ooe from '../constants';

export function formatPrice(price = 0.0) {
  return `$${parseFloat(price).toFixed(2)}`;
}

export function formatItemName(name) {
  if (!name || typeof name !== 'string') {
    return '';
  }
  return name.replace('<sup>®</sup>', '®').replace('<sup>™</sup>', '™');
}

export function formatModifierName(mod) {
  const { action, itemTag, name } = mod;
  if (!name || typeof name !== 'string') {
    return '';
  } else if (action === 'REMOVE') {
    return `No ${name}`;
  } else if (contains(itemTag, ooe.ADD_ON_ITEMS)) {
    return `Add ${name}`;
  }
  return `${formatItemName(name)}`;
}

export function formatModifierQuantity(mod, itemQuantity) {
  const {
    calculatedPrice,
    itemTag,
    quantity,
    priceAdjustment,
  } = mod;
  // always show quantity for sauces
  if (contains(itemTag, ooe.SAUCES) || contains(itemTag, ooe.DRESSINGS)) {
    return quantity;
  }
  // return item level quantity for non sauce modifiers that have a price
  if ((calculatedPrice > 0 || priceAdjustment > 0) && !contains(itemTag, ooe.SAUCES)) {
    return itemQuantity;
  }
  return '';
}

export function formatModifierPrice(mod, itemQuantity) {
  const {
    calculatedPrice,
    itemTag,
    priceAdjustment,
  } = mod;

  // use pre-calculated price for sauces
  if (contains(itemTag, ooe.SAUCES)) {
    return `$${parseFloat(calculatedPrice).toFixed(2)}`;
  }
  // use pre-calculated price non sauce modifiers that have a price
  if (calculatedPrice > 0 && !contains(itemTag, ooe.SAUCES)) {
    return `$${parseFloat(priceAdjustment * itemQuantity).toFixed(2)}`;
  }
  // use price adjustment for combo meal modifiers that have a price
  if (priceAdjustment > 0 && !contains(itemTag, ooe.SAUCES)) {
    return `$${parseFloat(priceAdjustment * itemQuantity).toFixed(2)}`;
  }
  return '';
}

export function formatDate(date) {
  const day = moment(date).format('dddd');
  const longDate = moment(date).format('MMM Do');
  return `${day}, ${longDate}`;
}

export function formatPaper(paperGoods) {
  return paperGoods ? 'Please include serving utensils and napkins. ' : 'Please do not include serving utensils and napkins. ';
}

