import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Section, SectionHeader } from '../components';
import { selectFatalError } from '../reducers';
import { selectLocationName, selectLocationPhone } from '../reducers/order';
import Icon from '../components/Icon';
import ooeConstants from '../constants';

export class Error extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      className,
      error,
      locationName,
      locationPhone,
    } = this.props;
    const renderError = () => {
      if (error) {
        return (
          <h4 className="title">
            <Icon className="icon">error</Icon>{error(locationName, locationPhone)}
          </h4>
        );
      }
      return (
        <h4 className="title">
          <Icon className="icon">warning</Icon>
          {ooeConstants.timeoutError}
        </h4>
      );
    };
    return (
      <Section className={className}>
        <SectionHeader>
          Oops!
        </SectionHeader>
        {renderError()}
      </Section>
    );
  }
}

const StyledError = styled(Error)`
  padding: 5em;
  & .title {
    color: ${props => props.theme.text};
  }
  & .icon {
    height: 12px;
    width: 12px;
    margin: 0 5px;
  }
`;

Error.propTypes = {
  className: PropTypes.string.isRequired,
  error: PropTypes.func,
  locationName: PropTypes.string,
  locationPhone: PropTypes.string,
};

Error.defaultProps = {
  error: () => {},
  locationName: '',
  locationPhone: '',
};

function mapStateToProps(state) {
  return {
    error: selectFatalError(state),
    locationPhone: selectLocationPhone(state),
    locationName: selectLocationName(state),
  };
}

export default connect(mapStateToProps, null)(StyledError);
