import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatModifierName, formatModifierQuantity, formatModifierPrice } from '../../util/format';
import Row from '../Row/Row';
import Column from '../Column/Column';

function Modifiers({ item }) {
  const { modifiers, quantity, comboItems } = item;
  return (
    <StyledModifiers>
      { comboItems && comboItems.map(comboItem => (
        <React.Fragment key={comboItem.itemTag}>
          <>
            <Column data-cy={`${comboItem.itemTag}-name`} className="modifier name" xs="6">{formatModifierName(comboItem)}</Column>
            <Column data-cy={`${comboItem.itemTag}-quantity`} className="modifier quantity" xs="2">{formatModifierQuantity(comboItem, quantity)}</Column>
            <Column data-cy={`${comboItem.itemTag}-price`} className="modifier price" xs="3">
              {formatModifierPrice(comboItem, quantity)}
            </Column>
          </>
          { comboItem.modifiers && comboItem.modifiers.map(comboMod => (
            <Row key={comboMod.itemTag}>
              <Column data-cy={`${comboMod.itemTag}-name`} className="modifier name" xs="6">{formatModifierName(comboMod)}</Column>
              <Column data-cy={`${comboMod.itemTag}-quantity`} className="modifier quantity" xs="2">{formatModifierQuantity(comboMod, quantity)}</Column>
              <Column data-cy={`${comboMod.itemTag}-price`} className="modifier price" xs="3">
                {formatModifierPrice(comboMod, quantity)}
              </Column>
            </Row>
          ))}
        </React.Fragment>
      ))}
      { modifiers && modifiers.map(mod => (
        <React.Fragment key={mod.itemTag}>
          <Column data-cy={`${mod.itemTag}-name`} className="modifier name" xs="6">{formatModifierName(mod)}</Column>
          <Column data-cy={`${mod.itemTag}-quantity`} className="modifier quantity" xs="2">{formatModifierQuantity(mod, quantity)}</Column>
          <Column data-cy={`${mod.itemTag}-price`} className="modifier price" xs="3">
            {formatModifierPrice(mod, quantity)}
          </Column>
        </React.Fragment>
      )) }
      <Column xs="6" className="modifier special">{item.specialInstructions || ''}</Column>
    </StyledModifiers>
  );
}

const StyledModifiers = styled(Row)`
  & .modifier {
    text-align: left;
    font-size: 15px;
    font-weight: 200;
    letter-spacing: -0.5px;
    line-height: 26px;
  }
  & .name, .special {
    margin-left: 8.33%;
  }
  & .quantity {
    text-align: right;
  }
  & .special {
    color: #0f7d92;
  }
  & .price {
    float: right;
    text-align: right;
  }
`;

Modifiers.propTypes = {
  item: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ])).isRequired,
};

export default Modifiers;
