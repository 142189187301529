/* eslint-disable no-param-reassign */
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ooeConstants from '../constants';
import {
  selectCfaId,
  selectGuestDetails,
} from '../reducers/guest';
import { BugsnagErrorBoundaryPluginHelper } from '../components/BugsnagErrorBoundary/BugsnagErrorBoundary';
import { maskEmail, maskName } from '../util/utils';
import { getHash } from '../util/location';

let bugsnagEnabled = false;

/* istanbul ignore next reason: part of external callback from Bugsnag */
let getBugSnagStateData = () => ({});

const setBugsnagEnabled = (enabled = false) => {
  bugsnagEnabled = enabled;
};

const initializeGetBugSnagStateData = (initFunction) => {
  getBugSnagStateData = initFunction;
};

const leaveBreadcrumb = (name, metadata, state = 'manual') => {
  if (!bugsnagEnabled) {
    return false;
  }
  return Bugsnag.leaveBreadcrumb(name, metadata, state);
};

const startSession = () => {
  if (!bugsnagEnabled) {
    return;
  }
  leaveBreadcrumb('Start Session', { message: 'Manually starting new session' }, 'state');
  Bugsnag.startSession();
};

const setMetadata = ({ event, section, info }) => {
  if (!bugsnagEnabled || !info) {
    return;
  }
  event.addMetadata(section, { ...info });
};

/* istanbul ignore next reason: external code - called by Bugsnag */
const sendErrorFilter = (event) => {
  const excludeErrorMessagesWithTheseLabels = [
    // can filter on error text here
    // 'Test Error 2',
  ];

  const mapErrors = (errors) => errors.map((theError) => {
    const {
      errorClass,
      errorMessage,
      type,
      dummy,
    } = theError;
    return {
      errorClass,
      errorMessage,
      type,
      dummy,
    };
  });

  let sendStatus = true;
  if (!event.errors?.length) {
    leaveBreadcrumb('in sendErrorFilter with no event.errors', { message: 'Error not sent to Bugsnag' });
    sendStatus = false;
  } else if (event.errors?.every(error => !error.errorMessage)) {
    leaveBreadcrumb('in sendErrorFilter with event.errors but no error messages', { message: 'Error not sent to Bugsnag', error: mapErrors(event.errors) });
    sendStatus = false;
  } else if (event.errors.some(error => excludeErrorMessagesWithTheseLabels.find(msg => error.errorMessage?.includes(msg)))) {
    leaveBreadcrumb('in sendErrorFilter, error was filtered', { message: 'Error not sent to Bugsnag', error: mapErrors(event.errors) });
    sendStatus = false;
  }
  return sendStatus;
};

/* istanbul ignore next reason: external code - called by Bugsnag */
const sendBreadcrumbFilter = (breadcrumb) => {
  if (breadcrumb.type === 'user') {
    // Discard clicks with general targetText
    if (breadcrumb.metadata.targetText === '(...)') {
      return false;
    }
    delete breadcrumb.metadata.targetSelector;
  }

  if (breadcrumb.type === 'navigation') {
    if (breadcrumb.metadata?.title) {
      // eslint-disable-next-line no-console
      // console.warn('breadcrumb.type === navigation - deleting breadcrumb.metadata.title', breadcrumb.metadata.title);
      delete breadcrumb.metadata.title;
    }
    if (breadcrumb.metadata?.state) {
      // eslint-disable-next-line no-console
      // console.warn('breadcrumb.type === navigation - deleting breadcrumb.metadata.state', breadcrumb.metadata.state);
      delete breadcrumb.metadata.state;
    }
    if (breadcrumb.metadata?.prevState) {
      // eslint-disable-next-line no-console
      // console.warn('breadcrumb.type === navigation - deleting breadcrumb.metadata.prevState', breadcrumb.metadata.prevState);
      delete breadcrumb.metadata.prevState;
    }
  }

  if (breadcrumb.type === 'request') {
    // discard tagging & cookies requests
    const discardedRequests = [
      'mparticle',
      'amplitude',
      'google-analytics',
      'pinterest',
      'doubleclick',
      'cookielaw',
      'taplytics',
    ];
    const { request } = breadcrumb.metadata;
    if (discardedRequests.some(discard => request.includes(discard))) {
      return false;
    }
  }
  return true;
};

const setupBugsnag = (store) => {
  if (!ooeConstants.BUGSNAG_ENABLED) {
    // eslint-disable-next-line no-console
    console.debug('IN setupBugsnag, BUGSNAG NOT ENABLED');
    return;
  }

  setBugsnagEnabled(true);

  /* istanbul ignore next reason: external code, bugsnag calls us for onError, onSession, onBreadcrumb */
  Bugsnag.start({
    // we do not want to include console logs, too risky/error prone
    enabledBreadcrumbTypes: ['error', /* 'log',  */'navigation', 'request', 'user'],
    apiKey: ooeConstants.BUGSNAG_ATOB,
    autoTrackSessions: false,
    appVersion: ooeConstants.APP_VERSION,
    releaseStage: ooeConstants.BUGSNAG_RELEASE_STAGE,
    maxBreadcrumbs: 100,
    maxEvents: 100,
    plugins: [new BugsnagPluginReact(React)],
    redactedKeys: [
      'access_token', // exact match: "access_token"
      /^password$/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
      /^cc_/, // prefix match: "cc_number" "cc_cvv" "cc_expiry"
      /^credit/,
    ],
    onError: (event) => {
      const state = store.getState();
      const cfaId = selectCfaId(state);
      const hash = getHash();
      const token = hash.id_token;
      const dataFromToken = JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
      const guestDetails = selectGuestDetails(state);
      const id = cfaId || dataFromToken?.userId;
      const email = guestDetails?.email || dataFromToken?.email;

      event.setUser(id, maskEmail(email), `${maskName(guestDetails.first)} ${maskName(guestDetails.last)}`.trim());
      setMetadata({
        event,
        section: 'User',
        info: { cfaId: id },
      });
      setMetadata({
        event,
        section: 'State Data',
        info: getBugSnagStateData(store),
      });
      return sendErrorFilter(event);
    },

    onBreadcrumb: (breadcrumb) => sendBreadcrumbFilter(breadcrumb),

    onSession: (session) => {
      const state = store.getState();
      const cfaId = selectCfaId(state);
      const guestDetails = selectGuestDetails(state);
      session.setUser(cfaId, maskEmail(guestDetails.email), `${maskName(guestDetails.first)} ${maskName(guestDetails.last)}`.trim());
      return true;
    },
  });

  BugsnagErrorBoundaryPluginHelper.BugsnagErrorBoundaryPlugin = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
};

const notifyBugsnag = (errorClass, debug = {}) => {
  // this will not be true if Constants.BUGSNAG_ENABLED was false, including in Cypress tests
  if (!bugsnagEnabled) {
    return;
  }

  // use our response if it is already an Error object, otherwise create a new Error object from errorClass
  const error = debug.response instanceof Error ? debug.response : new Error(errorClass);

  Bugsnag.notify(error, /* istanbul ignore next */ (event) => {
    if (!sendErrorFilter(event)) {
      return false;
    }

    if (debug.response?.skipBugsnagReport) {
      // eslint-disable-next-line no-console
      leaveBreadcrumb('Skipping manual Bugsnag notify because debug.response.skipBugsnagReport is true', {
        debugResponse: debug.response,
        error,
        event,
      });
      return false;
    }

    event.context = debug.context;
    event.errorClass = errorClass; // Displays below title in Bugsnag
    event.errorMessage = debug.errorMessage;
    debug.response = {
      ok: debug.response?.ok,
      status: debug.response?.status,
      statusCode: debug.response?.responseData?.statusCode,
      statusText: debug.response?.statusText,
      type: debug.response?.type,
      url: debug.response?.url,
      body: debug.response?.body,
      bodyUsed: debug.response?.bodyUsed,
      redirected: debug.response?.redirected,
      cfaError: debug.response?.responseData?.cfaError,
      cfaErrorCode: debug.response?.responseData?.cfaError?.code,
      devNote: debug.response?.devNote,
    };

    event.addMetadata('errorInfo', { ...debug });
    event.groupingHash = event.groupingHash || event.context || event?.errors?.[0]?.errorMessage;
    return true;
  });
};

export {
  setBugsnagEnabled,
  initializeGetBugSnagStateData,
  leaveBreadcrumb,
  startSession,
  setMetadata,
  setupBugsnag,
  notifyBugsnag,
};
