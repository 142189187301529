import moment from 'moment/moment';
import { pathOr, pick } from 'ramda';
import { createSelector } from 'reselect';
import { formatPrice } from '../util/format';
import ooeConstants from '../constants';

export const types = {
  GET_ORDER_SUCCESS: '[Order] Get success',
  GET_ORDER_FAILURE: '[Order] Get failure',
  GET_LOCATION_SUCCESS: '[Order] Get location success',
  GET_LOCATION_FAILURE: '[Order] Get location failure',
  ADD_DELIVERY_TIP: '[Order] Add delivery tip',
  ADD_DELIVERY_TIP_SUCCESS: '[Order] Add delivery tip success',
  ADD_DELIVERY_TIP_FAILURE: '[Order] Add delivery tip failure',
  SUBMIT_ORDER: '[Order] Submit Order',
  SUBMIT_ORDER_SUCCESS: '[Order] Submit Order success',
  SUBMIT_ORDER_FAILURE: '[Order] Submit Order failure',
  FATAL_ERROR: '[Order] Fatal Error',
};

export const actions = {
  getOrderSuccess: (order) => ({ type: types.GET_ORDER_SUCCESS, order }),
  getOrderFailure: (error) => ({ type: types.GET_ORDER_FAILURE, error }),
  getLocationSuccess: (location) => ({ type: types.GET_LOCATION_SUCCESS, location }),
  getLocationFailure: (error) => ({ type: types.GET_LOCATION_FAILURE, error }),
  addDeliveryTip: (deliveryTipType = '', deliveryTipValue = '') => ({
    type: types.ADD_DELIVERY_TIP,
    deliveryTipType,
    deliveryTipValue,
  }),
  addDeliveryTipSuccess: (order) => ({ type: types.ADD_DELIVERY_TIP_SUCCESS, order }),
  addDeliveryTipFailure: (error) => ({ type: types.ADD_DELIVERY_TIP_FAILURE, error }),
  submitOrder: () => ({ type: types.SUBMIT_ORDER }),
  submitOrderSuccess: () => ({ type: types.SUBMIT_ORDER_SUCCESS }),
  submitOrderFailure: (error) => ({ type: types.SUBMIT_ORDER_FAILURE, error }),
  throwFatalError: () => ({ type: types.FATAL_ERROR }),
};

export const initialState = {
  destination: '',
  taxAmount: 0.0,
  subTotalAmount: 0.0,
  lineItems: [],
  location: [
    {
      locationContact: {
        streetAddress: {
          zipCode: {},
        },
      },
    },
  ],
  loading: { getOrder: true },
  deliveryTip: {},
};

const {
  GENERIC, SUBMIT_ORDER, ALREADY_SUBMITTED, CANCELLED_ORDER, addDeliveryTipError,
} = ooeConstants;

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDER_SUCCESS: {
      const { order } = action;
      if (order.status === ooeConstants.ORDER_STATUS.Submit) {
        return {
          ...state,
          error: ALREADY_SUBMITTED,
          loading: { getOrder: false },
        };
      }
      if (order.status === ooeConstants.ORDER_STATUS.Cancelled) {
        return {
          ...state,
          error: CANCELLED_ORDER,
          loading: { getOrder: false },
        };
      }
      return {
        ...state,
        ...order,
        loading: { getOrder: false },
      };
    }

    case types.GET_ORDER_FAILURE: {
      return {
        ...state,
        error: GENERIC,
        loading: { getOrder: false },
      };
    }

    case types.GET_LOCATION_SUCCESS: {
      const { location } = action;
      return {
        ...state,
        location,
      };
    }

    case types.ADD_DELIVERY_TIP: {
      const { deliveryTipType, deliveryTipValue } = action;
      return {
        ...state,
        loading: { addDeliveryTip: true },
        deliveryTip: {
          [deliveryTipType]: +deliveryTipValue,
        },
      };
    }

    case types.ADD_DELIVERY_TIP_SUCCESS: {
      const { order } = action;
      return {
        ...state,
        ...order,
        loading: { ...state.loading, addDeliveryTip: false },
      };
    }

    case types.ADD_DELIVERY_TIP_FAILURE: {
      return {
        ...state,
        loading: { ...state.loading, addDeliveryTip: false },
        error: { ...state.error, addDeliveryTip: addDeliveryTipError },
      };
    }

    case types.SUBMIT_ORDER: {
      return {
        ...state,
        loading: { ...state.loading, submitOrder: true },
        submitted: true,
      };
    }

    case types.SUBMIT_ORDER_SUCCESS: {
      const submitted = true;
      return { ...state, submitted, loading: { ...state.loading, submitOrder: false } };
    }

    case types.SUBMIT_ORDER_FAILURE: {
      return {
        ...state,
        error: SUBMIT_ORDER,
        loading: { ...state.loading, submitOrder: false },
      };
    }

    default:
      return state;
  }
};

export const selectOrder = (state) => state.order || {};
export const selectOrderForDXE = createSelector(selectOrder, (order) => {
  const orderToSend = {
    ...order,
    status: 'Submit',
  };

  const orderPropsForDXE = [
    'orderType',
    'status',
    'destination',
    'deliveryAddress',
    'id',
    'promiseDateTime',
    'paperGoods',
    'specialInstructions',
    'guestCount',
    'businessDate',
    'locationNumber',
    'lineItems',
    'deliveryTip',
  ];

  return pick(orderPropsForDXE, orderToSend);
});
export const selectOrderError = createSelector(selectOrder, (order) => {
  if (order.error === GENERIC) {
    return ooeConstants.genericOrderError;
  }
  if (order.error === SUBMIT_ORDER) {
    return ooeConstants.submitOrderError;
  }
  if (order.error === ALREADY_SUBMITTED) {
    return ooeConstants.alreadySubmittedError;
  }
  if (order.error === CANCELLED_ORDER) {
    return ooeConstants.cancelOrderError;
  }
  return null;
});
export const selectDestination = createSelector(selectOrder, (order) => order.destination);
export const selectLocationNumber = createSelector(selectOrder, (order) => order.locationNumber);
export const selectLocationName = createSelector(selectOrder, (order) =>
  pathOr('', ['location', 0, 'name'], order),
);
export const selectLocationPhone = createSelector(selectOrder, (order) => {
  const { areaCode, phoneNumber } = pathOr(
    {},
    ['location', 0, 'locationContact', 'daytimePhone'],
    order,
  );
  return `${areaCode}-${phoneNumber}`;
});
export const selectDestinationDetails = createSelector(selectOrder, (order) => {
  const { address1, address2, city, state, zipCode } = pathOr(
    '',
    ['location', 0, 'locationContact', 'streetAddress'],
    order,
  );
  const { areaCode, phoneNumber } = pathOr(
    '',
    ['location', 0, 'locationContact', 'daytimePhone'],
    order,
  );
  const { companyName } = order;
  return order.destination === 'Pickup' ? {
    addressLine1: address1,
    addressLine2: address2,
    city,
    state,
    zip: `${zipCode.zip}-${zipCode.zipExtension}`,
    restaurantPhone: `${areaCode}-${phoneNumber}`,
  }
    : {
      ...order.deliveryAddress,
      companyName,
    };
});
export const selectSubTotalAmount = createSelector(selectOrder, (order) => order.subTotalAmount);
export const selectFormattedTotals = createSelector(selectOrder, (order) => ({
  subTotal: formatPrice(order.subTotalAmount),
  tax: formatPrice(order.taxAmount),
  deliveryTipAmount: formatPrice(order.deliveryTip.tipAmount) || 0,
  taxAndTotal: formatPrice(
    order.subTotalAmount + order.taxAmount + (order.deliveryTip.tipAmount || 0),
  ),
}));
export const selectPotentialPoints = createSelector(selectOrder, (order) =>
  Math.floor(order.subTotalAmount * 10),
);
export const selectEventDetails = createSelector(selectOrder, (order) => ({
  time: moment(order.promiseDateTime).format('h:mm a'),
  guestCount: order.guestCount,
  paperGoods: order.paperGoods,
  specialInstructions: order.specialInstructions,
  date: moment(order.promiseDateTime),
}));
export const selectCartItems = createSelector(selectOrder, (order) =>
  order.lineItems.map((item) => ({
    tag: item.itemTag,
    quantity: item.quantity,
    name: item.name,
    itemPrice: item.retailPrice,
    modifiers: item.modifiers,
    comboItems: item.comboItems,
    specialInstructions: item.specialInstructions,
  })),
);
export const selectOrderIsLoading = createSelector(selectOrder, (order) => order.loading.getOrder);
export const selectSubmitPaymentIsLoading = createSelector(
  selectOrder,
  (order) => order.loading.submitOrder,
);
export const selectDeliveryTipIsDisplayed = createSelector(
  selectOrder,
  (order) => !!(order.destination === 'Delivery' && (ooeConstants.AVAILABLE_DELIVERY_TIP_LOCATIONS.includes(order.location[0].locationNumber))),
);
export const selectDeliveryTipLoading = createSelector(
  selectOrder,
  (order) => order.loading.addDeliveryTip,
);
export const selectSelectedPercentageTipValue = createSelector(
  selectOrder,
  (order) => order.deliveryTip.tipPercentOfSale,
);
export const selectSelectedCustomTipValue = createSelector(selectOrder, (order) => {
  let customValue = 0;
  if (order.deliveryTip.tipAmount !== null && order.deliveryTip.tipPercentOfSale === null) {
    customValue = order.deliveryTip.tipAmount;
  }
  return customValue;
});
export const selectPaymentSubmitted = createSelector(selectOrder, (order) => order.submitted);
