const maskText = '*****';

// mask email keeping first and last portion of the part before the @
export const maskEmail = (email) => {
  try {
    const idx = email.indexOf('@');
    if (idx < 0) {
      return maskText;
    }
    if (idx < 4) {
      return `${maskText}${email.substr(idx)}`;
    }
    return `${email.substr(0, 1)}${maskText}${email.substr(idx - 1, 1)}${email.substr(idx)}`;
  } catch (e) {
    return undefined;
  }
};

// mask a name keeping first and last letters if possible
export const maskName = (name) => {
  if (!name || typeof name !== 'string' || name.length === 0) {
    return '';
  }

  // If the length is less than 3, return the first character followed by 5 asterisks
  if (name.length < 3) {
    return `${name[0]}${maskText}`;
  }

  // For names with 3 or more characters, mask everything except the first and last character
  return `${name[0]}${maskText}${name[name.length - 1]}`;
};

// mask phone keeping first 2 and last 2 digits if possible
export const maskPhone = (phone) => {
  if (!phone || typeof phone !== 'string' || phone.length < 3) {
    return '';
  }
  const sanitizedPhone = phone.replace(/\D/g, '');
  if (sanitizedPhone.length < 3) {
    return '';
  }
  return `${sanitizedPhone.slice(0, 2)}${maskText}${sanitizedPhone.slice(-2)}`;
};
