import styled from 'styled-components';
import PropTypes from 'prop-types';
import logo from '../../assets/CFA_Symbol.svg';
import location from '../../assets/CFA_Location.png';

function MainHeader({ className, locationName, userName }) {
  return (
    <div className={className}>
      <img className="logo" src={logo} alt="Chick-fil-A Logo" />
      <div className="divider" />
      <img className="location" src={location} alt="Location" />
      <div className="title">{locationName}</div>
      { userName && <div className="user">Hi, {userName}!</div> }
    </div>
  );
}

MainHeader.propTypes = {
  className: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  userName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
};

const StyledMainHeader = styled(MainHeader)`
  position: relative;
  & .logo {
    height: 40px;
    margin: 13px 16px;
  }
  & .location {
    height: 35px;
    position: absolute;
    top: 16px;
    left: 90px;
  }
  & .title {
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.tertiary};
    position: absolute;
    left: 130px;
    top: 22px;
    font-weight: 600;
    font-size: 18px;
  }
  & .divider {
    position: absolute;
    left: 75px;
    top: 10px;
    bottom: 10px;
    border-left: 1px solid #c7c7c7;
  }
  & .user {
    color: ${props => props.theme.text};
    position: absolute;
    top: 25px;
    right: 20px;
  }
  @media (max-width: 450px) {
   & .location, .divider, .title {
      display: none;
   }
  }
`;

export default StyledMainHeader;
