/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

export const DeliveryTipsPercentageButton = React.forwardRef((props, ref) => {
  const {
    tipPercentageButtonId,
    value,
    onClickHandler,
    disabled,
    children,
    checked,
  } = props;

  return (
    <StyledPercentageButton>
      <input id={tipPercentageButtonId} type="radio" value={value} name="percentage" ref={ref} onChange={onClickHandler} disabled={disabled} checked={checked} />
      <label htmlFor={tipPercentageButtonId}>{children}</label>
    </StyledPercentageButton>
  );
});

export const StyledPercentageButton = styled('div')`
      min-width: 85px;
      min-height: 35px;
      position: relative;
      margin: 5px;
      & label,
      & input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
      }
      & label {
        cursor: pointer;
        z-index: 90;
        width: 100%;
        height: 100%;
        text-align: center;
        border: solid 1px #C20726;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #C20726;
        border-radius: 24px;
      }
      & input[type="radio"] {
        opacity: 0.011;
        z-index: 100;
        &:disabled {
          cursor: auto;
        }
        &:checked + label {
          background: linear-gradient(-180deg, rgba(254,67,108,0.99) 0%, rgba(221,0,49,0.99) 49%, #C20726 100%);
          border: none;
          color: ${props => props.theme.secondary}
        }
        &:disabled + label {
          background: ${props => props.theme.disabled};
          color: ${props => props.theme.text};
          border-color: ${props => props.theme.text};
          cursor: auto;
        }
      }
`;

DeliveryTipsPercentageButton.propTypes = {
  tipPercentageButtonId: PropTypes.string,
  value: PropTypes.string,
  onClickHandler: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  children: PropTypes.string,
};

DeliveryTipsPercentageButton.defaultProps = {
  tipPercentageButtonId: '',
  value: '',
  onClickHandler: () => {},
  disabled: false,
  checked: false,
  children: '',
};

export default DeliveryTipsPercentageButton;
