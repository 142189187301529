import ooeConstants from '../constants';

export function getHash() {
  const hash = window.location.hash || /* istanbul ignore next */ '';
  if (hash.length > 0) {
    const hashString = hash.substr(1);
    const replaceChars = decodeURI(hashString)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"');
    return JSON.parse(`{"${replaceChars}"}`);
  }
  return {};
}

export function getActiveStep(guestLogin) {
  const thisPage = window.location.pathname;
  let active = null;
  if (thisPage === ooeConstants.PATH_NAME.SUMMARY) {
    active = 1;
  } else if (thisPage === ooeConstants.PATH_NAME.GUEST_LOGIN) {
    active = 2;
  } else if (thisPage === ooeConstants.PATH_NAME.PAYMENT) {
    active = guestLogin ? 3 : 2;
  } else if (thisPage === ooeConstants.PATH_NAME.SUCCESS) {
    active = guestLogin ? 4 : 3;
  }
  return active;
}
