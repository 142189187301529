import styled from 'styled-components';
import PropTypes from 'prop-types';
import Column from '../Column/Column';
import SocialIcons from './SocialIcons';
import ooeConstants from '../../constants';

function FooterLinks({ className }) {
  return (
    <div className={className}>
      <Column xs="12" sm="12" md="12" lg="9">
      <div className="red-links">
          <a href="https://www.chick-fil-a.com/Nutrition-Allergens" target="_blank" rel="noopener noreferrer">Allergens</a>
          <a href="https://www.chick-fil-a.com/Contact-Support" target="_blank" rel="noopener noreferrer">Contact Us</a>
          <a href="https://www.chick-fil-a.com/Careers" target="_blank" rel="noopener noreferrer">Careers</a>
          <a href="https://www.chick-fil-a.com/Locations/Browse" target="_blank" rel="noopener noreferrer">Locations</a>
          <a href="https://www.chick-fil-a.com/Franchising" target="_blank" rel="noopener noreferrer">Franchising</a>
          <a href="https://www.cfaapparel.com/" target="_blank" rel="noopener noreferrer">Merchandising</a>
          <a href="https://www.chick-fil-a.com/Do-Business-With-Us" target="_blank" rel="noopener noreferrer">Do business with us</a>
        </div>
        <div className="gray-links">
          <a href="https://www.chick-fil-a.com/Legal" target="_blank" rel="noopener noreferrer">Legal</a>
          <a href="https://www.chick-fil-a.com/Legal#privacy_policy" target="_blank" rel="noopener noreferrer">Privacy policy</a>
          <a href="https://www.chick-fil-a.com/Legal#supply_chain" target="_blank" rel="noopener noreferrer">California Transparency in Supply Chians Act</a>
        </div>
      </Column>
      <SocialIcons />
      <Column xs="12" sm="12" md="12" lg="9">
        <div className="copyright">Copyright Chick-fil-A &copy; 2025</div>
        <div className="version">Version {`${ooeConstants.APP_VERSION}`}</div>
      </Column>
    </div>
  );
}

FooterLinks.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledFooterLinks = styled(FooterLinks)`
  min-height: 110px;
  font-family: ${props => props.theme.fontFamily};
  padding: 15px;
  box-sizing: border-box;
  & .red-links, .gray-links {
    display: flex;
  }
  & .red-links a {
    font-size: 14px;
    font-weight: bold;
    color: ${props => props.theme.tertiary};
    padding: 20px 12px 10px;
  }
  & .gray-links a, .copyright, .version {
    font-size: 10px;
    color: gray;
    font-weight: bold;
    padding: 5px 15px;
  }
  & .copyright {
    font-weight: normal;
  }
  @media (max-width: 900px) {
    & .red-links, .gray-links {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  @media (max-width: 700px) {
    & .red-links, .gray-links {
      flex-direction: column;
    }
    & .red-links a, .gray-links a, .copyright {
      padding: 15px 15px 10px;
    }
  }
`;

export default StyledFooterLinks;
