import PropTypes from 'prop-types';
import styled from 'styled-components';
import Column from '../Column/Column';
import Row from '../Row/Row';

function Totals({
  className,
  subTotal,
  tax,
  taxAndTotal,
  deliveryTipAmount,
  deliveryTipIsDisplayed,
  deliveryTipLoading,
}) {
  return (
    <div className={className}>
      <Row>
        <Column className="small-title" xs="9">Subtotal</Column>
        <Column xs="3" data-cy="subtotal">{subTotal}</Column>
      </Row>
      {deliveryTipIsDisplayed && (
        <Row className="deliveryTip">
          <Column className="small-title" xs="9">Tip Amount</Column>
          {deliveryTipLoading
            ? <Column xs="3" className="calculating" data-cy="tax">Calculating</Column>
            : <Column xs="3" data-cy="tax">{deliveryTipAmount}</Column>}
        </Row>
      )}
      <Row>
        <Column className="small-title" xs="9">Tax</Column>
        <Column xs="3" data-cy="tax">{tax}</Column>
      </Row>
      <Row>
        <Column className="small-title" xs="9">Total</Column>
        {deliveryTipLoading
          ? <Column xs="3" data-cy="tax">Calculating</Column>
          : <Column xs="3" data-cy="tax-and-total">{taxAndTotal}</Column>}
      </Row>
    </div>
  );
}

const StyledTotals = styled(Totals)`
  color: ${props => props.theme.text};
  letter-spacing: -0.5px;
  clear: both;
  margin: 30px 0;
  font-weight: 200;
  
  & div {
    text-align: right;
      margin: 5px 0;
  }
  
  & .small-title {
     line-height: 24px;
  }
  
`;

Totals.propTypes = {
  className: PropTypes.string.isRequired,
  subTotal: PropTypes.string.isRequired,
  tax: PropTypes.string.isRequired,
  taxAndTotal: PropTypes.string.isRequired,
  deliveryTipAmount: PropTypes.string,
  deliveryTipIsDisplayed: PropTypes.bool,
  deliveryTipLoading: PropTypes.bool,
};

Totals.defaultProps = {
  deliveryTipAmount: '',
  deliveryTipIsDisplayed: false,
  deliveryTipLoading: false,
};

export default StyledTotals;
