/*
  primary
    red: #DD0031
    white: #FFFFFF
  secondary:
    red: #AF272F
    navy: #004F71
    aqua: #3EB1C8
    gray: #5B6770
  tertiary
    orange: #FFB549
    pale yellow: #F5E1A4
    forest green: #00635B
    kelly green: #249E6B
    maroon: #5D3754
    plum: #994878
    orange sherbet: #Fa9370
    pink: #F8C1B8
    nude: #F5E3CC
 */
import { theme as cowponentsTheme } from '@cfacorp/cowponents/dist/theme';

const theme = {
  primary: '#004F71',
  secondary: '#ffffff',
  tertiary: '#DD0031',
  accent: '#3EB1C8',
  background: '#f5f5f5',
  text: '#7c8891',
  textColor: '#7c8891',
  outline: '#969494',
  disabled: '#e5e0e0',
  error: '#FB4B3E',
  fontFamily: 'Apercu, sans-serif',
  caecilia: 'Caecilia, sans-serif',
  linearGradient: 'linear-gradient(-180deg, rgba(254,67,108,0.99) 0%, rgba(221,0,49,0.99) 49%, #C20726 100%)',
  colors: {
    tertiary: '#DD0031',
    textColor: '#7c8891',
    primary: '#004F71',
    secondary: '#ffffff',
    smallTitle: '#0f7d92',
  },
  fonts: {
    sans: 'Apercu, sans-serif',
    caecilia: 'Caecilia, sans-serif',
  },
  Button: {
    '&&:disabled': {
      color: 'white',
      background: '#e5e0e0',
      cursor: 'initial',
    },
  },
  buttons: {
    ...cowponentsTheme.buttons,
    customer: {
      fontSize: '15px',
      color: '#fff',
      background:
        'linear-gradient(-180deg, rgba(254,67,108,0.99) 0%, rgba(221,0,49,0.99) 49%, #C20726 100%)',
      letterSpacing: '-.56px',
      lineHeight: '17px',
      padding: '10px 17px 13px',
      textTransform: 'none',
      transition: 'all 0.5s ease-in-out',
      fontWeight: '200',
      fontFamily: 'Apercu, sans-serif',
      border: 'none',
      borderRadius: '24px',
      '&:hover:enabled': {
        cursor: 'pointer',
      },
    },
    basic: {
      fontSize: '14px',
      fontFamily: 'Apercu, sans-serif',
      border: 'none',
      background: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:focus': {
        outline: 'none',
      },
    },
  },
};

export default theme;
