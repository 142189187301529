import moment from 'moment/moment';
import { values, clone, prop, pathOr, propOr, contains } from 'ramda';
import { createSelector } from 'reselect';
import ooeConstants from '../constants';
import { types as guestTypes } from './guest';

export const types = {
  GET_VAULTED_CARDS_SUCCESS: '[Payment] Get Vaulted Cards Success',
  GET_VAULTED_CARDS_FAILURE: '[Payment] Get Vaulted Cards Failure',
  GET_PAYMENT_FRAME: '[Payment] Get payment frame',
  GET_PAYMENT_FRAME_SUCCESS: '[Payment] Get frame success',
  GET_PAYMENT_FRAME_FAILURE: '[Payment] Get frame failure',
  ADD_CARD: '[Payment] Add New Card',
  ADD_CARD_SUCCESS: '[Payment] Add New Card success',
  ADD_CARD_FAILURE: '[Payment] Add New Card failure',
  DELETE_CARD: '[Payment] Delete Vaulted card',
  DELETE_CARD_SUCCESS: '[Payment] Delete Vaulted card success',
  DELETE_CARD_FAILURE: '[Payment] Delete Vaulted card failure',
  ACCOUNT_SELECTED: '[Payment] Account selected',
};

export const actions = {
  getVaultedCardsSuccess: (vaultedCards) => ({
    type: types.GET_VAULTED_CARDS_SUCCESS,
    vaultedCards,
  }),
  getVaultedCardsFailure: (error) => ({ type: types.GET_VAULTED_CARDS_FAILURE, error }),
  getPaymentFrame: () => ({ type: types.GET_PAYMENT_FRAME }),
  getPaymentFrameSuccess: (paymentFrame) => ({
    type: types.GET_PAYMENT_FRAME_SUCCESS,
    paymentFrame,
  }),
  getPaymentFrameFailure: (error) => ({ type: types.GET_PAYMENT_FRAME_FAILURE, error }),
  addCard: (paymentMethodRecord) => ({ type: types.ADD_CARD, paymentMethodRecord }),
  addCardSuccess: (account) => ({ type: types.ADD_CARD_SUCCESS, account }),
  addCardFailure: (error) => ({ type: types.ADD_CARD_FAILURE, error }),
  deleteCard: (card) => ({ type: types.DELETE_CARD, card }),
  deleteCardSuccess: (card) => ({ type: types.DELETE_CARD_SUCCESS, card }),
  deleteCardFailure: (error) => ({ type: types.DELETE_CARD_FAILURE, error }),
  accountSelected: (selectedAccount) => ({ type: types.ACCOUNT_SELECTED, selectedAccount }),
};

const keys = {
  VAULTED_CARDS: 'VAULTED_CARDS',
  PAYMENT_FRAME: 'PAYMENT_FRAME',
  ADD_CARD: 'ADD_CARD',
  DELETE_CARD: 'DELETE_CARD',
};

export const initialState = {
  loading: { vaultedCards: true },
  iframeUrl: '',
  submitted: false,
  selectedAccount: {},
  vaultedCards: [],
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Vaulted Card Cases
    case guestTypes.GET_GUEST_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: { vaultedCards: true },
      };
    }

    case types.GET_VAULTED_CARDS_SUCCESS: {
      const { vaultedCards } = action;
      return {
        ...state,
        vaultedCards: vaultedCards.filter((card) =>
          contains(propOr('', ['cardType'], card).toUpperCase(), ooeConstants.allowedCardTypes),
        ),
        loading: { ...state.loading, vaultedCards: false },
      };
    }

    case types.GET_VAULTED_CARDS_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          vaultedCards: prop(keys.VAULTED_CARDS, ooeConstants.PAYMENT_ERROR_MESSAGES),
        },
        loading: { ...state.loading, vaultedCards: false },
      };
    }

    // Payment iframe cases
    case types.GET_PAYMENT_FRAME: {
      return {
        ...state,
        loading: { ...state.loading, iframe: true },
        error: { ...state.error, paymentFrameError: '' },
      };
    }

    case types.GET_PAYMENT_FRAME_SUCCESS: {
      const { paymentFrame } = action;
      return {
        ...state,
        paymentFrameConfigs: paymentFrame,
        loading: { ...state.loading, iframe: false },
      };
    }

    case types.GET_PAYMENT_FRAME_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          paymentFrameError: prop(keys.PAYMENT_FRAME, ooeConstants.PAYMENT_ERROR_MESSAGES),
        },
        loading: { ...state.loading, iframe: false },
      };
    }

    // Add new card cases
    case types.ADD_CARD: {
      return { ...state, loading: { ...state.loading, addCard: true } };
    }

    case types.ADD_CARD_SUCCESS: {
      const { account } = action;
      const { alias, accountId } = account;
      const cardToAdd = {
        ...account,
        accountDisplay: alias,
        id: accountId,
      };
      // If the card is not a duplicate, add the new card to the vaulted cards array
      const { vaultedCards } = clone(state);
      const isDuplicate = vaultedCards.some((vaultedCard) => vaultedCard.id === cardToAdd.id);
      if (!isDuplicate) {
        vaultedCards.push(cardToAdd);
      }
      return {
        ...state,
        vaultedCards,
        loading: { ...state.loading, addCard: false },
        selectedAccount: cardToAdd,
        error: {},
      };
    }

    case types.ADD_CARD_FAILURE: {
      const getErrorMessage = typeof action.error === 'object'
        ? prop('DEFAULT', ooeConstants.PAYMENT_ERROR_MESSAGES)
        : action.error;
      return {
        ...state,
        loading: { ...state.loading, addCard: false },
        error: {
          ...state.error,
          addCard: getErrorMessage,
        },
      };
    }

    // Delete Card Cases
    case types.DELETE_CARD: {
      return {
        ...state,
        loading: { ...state.loading, deleteCard: true },
      };
    }

    case types.DELETE_CARD_SUCCESS: {
      const { card } = action;
      // If card to delete is currently selected clear selectedAccount
      if (card.id === state.selectedAccount.id) {
        return {
          ...state,
          selectedAccount: {},
          loading: { ...state.loading, deleteCard: false },
          error: {
            ...state.error,
            deleteCardError: '',
          },
          vaultedCards: state.vaultedCards.filter((vaultedCard) => vaultedCard.id !== card.id),
        };
      }
      return {
        ...state,
        loading: { ...state.loading, deleteCard: false },
        error: {
          ...state.error,
          deleteCardError: '',
        },
        vaultedCards: state.vaultedCards.filter((vaultedCard) => vaultedCard.id !== card.id),
      };
    }

    case types.DELETE_CARD_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          deleteCardError: prop(keys.DELETE_CARD, ooeConstants.PAYMENT_ERROR_MESSAGES),
        },
        loading: { ...state.loading, deleteCard: false },
      };
    }

    // Account selected cases
    case types.ACCOUNT_SELECTED: {
      const { selectedAccount } = action;
      selectedAccount.id = action.accountId ? action.accountId : selectedAccount.id;
      return {
        ...state,
        selectedAccount,
      };
    }

    default:
      return state;
  }
};

export const selectPayment = (state) => state.payment;

// Loading selectors
export const selectVaultedCardsIsLoading = createSelector(
  selectPayment,
  (payment) => payment.loading.vaultedCards,
);
export const selctAddCardIsLoading = createSelector(
  selectPayment,
  (payment) => payment.loading.addCard,
);
export const selectIframeIsLoading = createSelector(
  selectPayment,
  (payment) => payment.loading.iframe,
);
export const selectDeleteCardIsLoading = createSelector(
  selectPayment,
  (payment) => payment.loading.deleteCard,
);

// Error selectors
export const selectError = createSelector(selectPayment, (payment) => payment.error);
export const selectPaymentErrors = createSelector(selectError, (error) =>
  values(error).filter((err) => err !== ''),
);
export const selectPaymentFrameError = createSelector(
  selectError,
  (error) => error.paymentFrameError,
);

// Account / Card selectors
export const selectSelectedAccount = createSelector(
  selectPayment,
  (payment) => payment.selectedAccount,
);
export const selectPaymentFrameConfigs = createSelector(
  selectPayment,
  (payment) => payment.paymentFrameConfigs,
);
export const selectCorrelationID = createSelector(
  selectPayment,
  (payment) => payment.correlationID,
);
export const selectAccountId = createSelector(selectPayment, (payment) =>
  pathOr('', ['selectedAccount', 'id'], payment),
);
export const selectPaymentForDXE = createSelector(selectAccountId, (accountId) => ({
  accountId,
  paymentType: 'ACCOUNT',
}));
export const selectVaultedCards = createSelector(selectPayment, (payment) =>
  payment.vaultedCards.map((vaultedCard) => {
    const isExpired = (card) => {
      let expMonth = pathOr('', ['expirationMonth'], card);
      if (expMonth.length < 2) {
        expMonth = `0${card.expirationMonth}`;
      }
      const lastDayofMonth = moment(`${expMonth}${card.expirationYear}`, 'MMYY').daysInMonth();
      const expirationDate = moment(
        `${expMonth}${lastDayofMonth}20${card.expirationYear}`,
        'MMDDYYYY',
      );
      const now = moment();
      if (expirationDate.isBefore(now)) {
        return true;
      }
      return false;
    };
    return {
      ...vaultedCard,
      isExpired: isExpired(vaultedCard),
    };
  }),
);
