import PropTypes from 'prop-types';
import { Component } from 'react';
import { StepperButton, StepperGroup } from '@cfacorp/cowponents';
import { getActiveStep, getHash } from '../../util/location';

class ProgressStepper extends Component {
  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(step) {
    const { path, value } = step;
    const { history, stepCompleted } = this.props;
    stepCompleted(value);
    history.push(`${path}#id_token=${getHash().id_token}`);
  }

  render() {
    const { steps, guestLogin, loading } = this.props;
    const active = getActiveStep(guestLogin);
    if (active && !loading) {
      return (
        <StepperGroup active={active} style={{ padding: '3em auto' }}>
          { steps.map(step => (
            <StepperButton
              key={step.value}
              value={step.value}
              title={step.title}
              completed={step.completed}
              disabled={!step.completed || step.disabled}
              onClick={() => this.handleOnClick(step)}
            />
          ))}
        </StepperGroup>
      );
    }
    return null;
  }
}

ProgressStepper.propTypes = {
  guestLogin: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  stepCompleted: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ProgressStepper;
