import { Component } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Button, Input } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import Column from '../Column/Column';
import Row from '../Row/Row';
import logo from '../../assets/CFA_OneLogo.jpg';
import { getHash } from '../../util/location';
import ButtonSpinner from '../Spinner/ButtonSpinner';

function passwordHasNumber(strToTest) {
  const passwordFilter = /(?:[A-Za-zÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð].*?\d|\d.*?[A-Za-zÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð])/;
  return passwordFilter.test(strToTest);
}

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      message: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.isCreateAccountEnabled = this.isCreateAccountEnabled.bind(this);
    this.handleGuestCheckoutClick = this.handleGuestCheckoutClick.bind(this);
  }

  handleGuestCheckoutClick() {
    const { checkoutAsGuest, stepCompleted } = this.props;
    checkoutAsGuest();
    stepCompleted(2);
  }

  handleChange(e, field) {
    const { value } = e.target;
    const state = { ...this.state, [field]: value };
    this.setState(state);
    this.passwordCheck(state);
  }

  passwordCheck({ password, confirmPassword }) {
    if ((confirmPassword !== password) && (confirmPassword.length > 0)) {
      this.setState({ message: 'Passwords do not match' });
    } else if (password.length < 8) {
      this.setState({ message: 'Password must be at least 8 characters' });
    } else if (!passwordHasNumber(password)) {
      this.setState({ message: 'Password must contain a number' });
    } else {
      this.setState({ message: '' });
    }
  }

  isCreateAccountEnabled() {
    const { password, confirmPassword } = this.state;
    return password === confirmPassword && passwordHasNumber(password) && password.length >= 8;
  }

  render() {
    const {
      className,
      convertGuest,
      guestDetails,
      convertGuestIsLoading,
      accountConverted,
      points,
    } = this.props;
    const { confirmPassword, message } = this.state;

    const renderAccountSuccess = () => (
      <Column xs="12">
        <img className="logo" src={logo} alt="Chick-fil-A Logo" />
        <div className="account-success">Chick-fil-A One Account created successfully</div>
        <h3 className="account-name">{`${guestDetails.first} ${guestDetails.last}`}</h3>
        <div className="account-email">{guestDetails.email}</div>
        <NavLink to={`/payment/#id_token=${getHash().id_token}`}>
          <Button
            variant="customer"
            className="account-button"
          >
            Continue to Payment
          </Button>
        </NavLink>
      </Column>
    );

    return (
      <StyledCreateAccount className={className}>
        {accountConverted ? renderAccountSuccess()
          : (
            <>
              <Column xs="12" sm="6" className="benefits">
                <img className="logo" src={logo} alt="Chick-fil-A Logo" />
                <ul>
                  <li>
                    <span aria-hidden data-icon="&#xe91b;" />
                    {`Get ${points} points for this order`}
                  </li>
                  <li>
                    <span aria-hidden data-icon="&#xe91b;" />
                    Have a single log-in for all of your Chick-fil-A accounts
                  </li>
                  <li>
                    <span aria-hidden data-icon="&#xe91b;" />
                    Review your order history
                  </li>
                  <li>
                    <span aria-hidden data-icon="&#xe91b;" />
                    Update / manage your payment options
                  </li>
                  <li>
                    <span aria-hidden data-icon="&#xe91b;" />
                    Save your favorite restaurants and orders
                  </li>
                </ul>
              </Column>
              <Column xs="12" sm="5" className="passwords">
                <Input
                  style={{ display: 'none' }}
                  id="TODO: use this so password can be saved in browser / lastpass"
                  type="email"
                  defaultValue={guestDetails.email}
                />
                <Input
                  placeholder="Password"
                  type="password"
                  onKeyUp={e => this.handleChange(e, 'password')}
                />
                <Input
                  placeholder="Confirm Password"
                  type="password"
                  onKeyUp={e => this.handleChange(e, 'confirmPassword')}
                />
                <p className="password-check">{message}</p>
                <div className="disclaimer">
                  By clicking Create Account and Continue, you agree to the&nbsp;
                  <a
                    href="https://www.chick-fil-a.com/Legal"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href="https://www.chick-fil-a.com/Legal"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Notice
                  </a>
                  { convertGuestIsLoading ? <ButtonSpinner className="button-spinner" width="221px" /> : (
                    <Button
                      className="account"
                      variant="customer"
                      onClick={() => convertGuest(confirmPassword)}
                      disabled={!this.isCreateAccountEnabled()}
                    >
                      Create Account and Continue
                    </Button>
                  ) }
                  <NavLink className="continue" to={`/payment/#id_token=${getHash().id_token}`}>
                    <Button className="guest" variant="basic" p="1px 7px 2px" onClick={this.handleGuestCheckoutClick}>
                      Checkout as a guest
                    </Button>
                  </NavLink>
                </div>
              </Column>
            </>
          )}
      </StyledCreateAccount>
    );
  }
}

CreateAccount.propTypes = {
  email: PropTypes.string,
  className: PropTypes.string,
  convertGuest: PropTypes.func.isRequired,
  checkoutAsGuest: PropTypes.func.isRequired,
  stepCompleted: PropTypes.func.isRequired,
  guestDetails: PropTypes.objectOf(PropTypes.string),
  convertGuestIsLoading: PropTypes.bool,
  accountConverted: PropTypes.bool,
  points: PropTypes.number.isRequired,
};

CreateAccount.defaultProps = {
  email: '',
  className: '',
  guestDetails: {},
  convertGuestIsLoading: false,
  accountConverted: false,
};

const StyledCreateAccount = styled(Row)`
  color: ${props => props.theme.text};
  margin-top: 30px;

  & .passwords, .benefits {
    box-sizing: border-box;
    font-weight: 200;
  }
  & .passwords {
    float: right;
  }
  & .benefits {
    font-size: 15px;
  }
  & .benefits ul {
    line-height: 35px;
    text-align: left;
    list-style-type: none;
    text-indent: -30px;
  }
  & .logo {
    width: 75px;
  } 
  & .disclaimer {
    font-size: 10px;
    line-height: 16px;
    margin-top: 29px;
  }
  & a, .guest {
    font-size: 10px;
    text-align: center;
    color: ${props => props.theme.tertiary};
    text-decoration: none;
  }
  & .guest {
    font-size: 13px;
  }
  & .account, .button-spinner {
    margin: 10px auto;
  }

  & input {
    font-size: 1em;
    border: 2px solid ${props => props.theme.outline};
    border-radius: 7px;
    width: calc(100% - 14px);
    padding-left: 7px;
    color: #7c8891;
  }

  & input::placeholder {
    font-weight: 200;
    font-size: 15px;
    color: ${props => props.theme.disabled}
  }
  & .password-check {
    height: 20px;
    margin: 0 14px;
    color: ${props => props.theme.tertiary};
    font-size: 12px;
    text-align: left;
  }
   & [data-icon]:before {
    font-family: 'icomoon';
    content: attr(data-icon);
    font-size: 10px;
    margin: 10px;
    color: ${props => props.theme.tertiary};
  }
  & .account-success {
    font-size: 17px;
    font-weight: 400;
    margin: 30px 0;
  }
  & .account-name {
    color: ${props => props.theme.primary};
    margin-bottom: 8px;
  }
  & .account-email {
    font-size: 12px;
  }
  & .account-button {
    margin: 50px auto;
    letter-spacing: -.53px;
  }
  @media (max-width: 500px) {
    & .account, .account-button {
      width: 100%;
    }
  }
  
`;

export default CreateAccount;
