import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import logo from '../../assets/CFA_OneLogo.jpg';

export class OrderSuccess extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <img className="logo" src={logo} alt="Chick-fil-A Logo" />
        <h2>Thanks for your order!</h2>
        <p className="thanks">
          It&#39;s been a pleasure serving you! Check your email for your order confirmation.
          We appreciate your business and hope you have a great day.
        </p>
      </div>
    );
  }
}

export const StyledOrderSuccess = styled(OrderSuccess)`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.text};
  margin: 50px auto 100px;
  text-align: center;
  max-width: 600px;
  line-height: 2em;
  & .logo {
    width: 100px;
  }
  & .thanks {
    margin: 30px;
  }
`;

OrderSuccess.propTypes = {
  className: PropTypes.string.isRequired,
};

export default connect(null, null)(StyledOrderSuccess);
