import { Observable } from 'rxjs/Observable';
import { prop, propOr } from 'ramda';
import ooeConstants from '../constants';
import handleErrors from '../util/handleErrors';
import errorCodeFromSuccess from '../util/errorCodeFromSuccess';
import { leaveBreadcrumb, notifyBugsnag } from './bugsnag';

export function getPaymentiFrameParameters(apiKey) {
  const baseUrl = ooeConstants.URL.PAYMENT;
  const url = `${baseUrl}/giftcard/3.0/me/session`;

  const bugsnagInfo = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PAYMENTS,
    breadcrumb: 'Attempting to get payment iFrame parameters',
    context: 'Get payment iFrame parameters',
    info: {
      method: 'POST',
      baseUrl,
      url,
      apiKey: `******${apiKey?.slice?.(-6)}`,
    },
  };

  leaveBreadcrumb(bugsnagInfo.context, bugsnagInfo);

  const request = fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => handleErrors(response, bugsnagInfo))
    .then((configs) => {
      const { statusCode, statusMessage } = configs;
      const result = errorCodeFromSuccess(configs);

      if (result) {
        const paymentErrorMessage = prop('PAYMENT_FRAME', ooeConstants.PAYMENT_ERROR_MESSAGES);
        notifyBugsnag(bugsnagInfo.errorClass, {
          context: bugsnagInfo.context,
          breadcrumb: bugsnagInfo.breadcrumb,
          errorMessage: 'getPaymentiFrameParameters failed',
          errorMessage2: paymentErrorMessage,
          info: bugsnagInfo.info,
          statusCode,
          statusMessage,
          errorCodeFromSuccess: result,
        });
        throw paymentErrorMessage;
      } else {
        return configs;
      }
    })
    .catch((err) => {
      // detect a normal exception and notify bugsnag
      // i.e. one that has not already been sent to Bugsnag above
      if (err?.name && err?.message) {
        notifyBugsnag(bugsnagInfo.errorClass, {
          context: bugsnagInfo.context,
          breadcrumb: bugsnagInfo.breadcrumb,
          errorMessage: 'getPaymentiFrameParameters failed',
          errorMessage2: err,
          info: bugsnagInfo.info,
        });
      }
      throw err;
    });

  return Observable.fromPromise(request);
}

export function addNewCardApi(apiKey, paymentMethodRecord) {
  const baseUrl = ooeConstants.URL.PAYMENT;
  const url = `${baseUrl}/giftcard/3.0/me/cors/confirmation`;

  // filter out some items we don't want to send
  const {
    credit,
    accessToken,
    fdCustomerId,
    correlationId,
    token,
    ...paymentMethodRecordFiltered
  } = paymentMethodRecord;

  const bugsnagInfo = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PAYMENTS,
    breadcrumb: 'Attempting to add new payment card',
    context: 'Add New Card',
    info: {
      method: 'POST',
      baseUrl,
      url,
      apiKey: `******${apiKey?.slice?.(-6)}`,
      paymentMethodRecordFiltered,
    },
  };

  leaveBreadcrumb(bugsnagInfo.context, bugsnagInfo);

  const request = fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(paymentMethodRecord),
  })
    .then((response) => handleErrors(response, bugsnagInfo))
    .then((card) => {
      if (card.failureCode) {
        const defaultMessage = prop('DEFAULT', ooeConstants.PAYMENT_ERROR_MESSAGES);
        const paymentErrorMessage = propOr(defaultMessage, card.failureCode, ooeConstants.FIRST_DATA_ERROR_CODES);
        notifyBugsnag(bugsnagInfo.errorClass, {
          context: bugsnagInfo.context,
          breadcrumb: bugsnagInfo.breadcrumb,
          errorMessage: 'addNewCardApi failed',
          paymentErrorMessage,
          defaultMessage,
          info: bugsnagInfo.info,
        });
        throw paymentErrorMessage;
      } else {
        return card;
      }
    })
    .catch((err) => {
      // detect a normal exception and notify bugsnag
      // i.e. one that has not already been sent to Bugsnag above
      if (err?.name && err?.message) {
        notifyBugsnag(bugsnagInfo.errorClass, {
          context: bugsnagInfo.context,
          breadcrumb: bugsnagInfo.breadcrumb,
          errorMessage: 'addNewCardApi failed',
          errorMessage2: err,
          info: bugsnagInfo.info,
        });
      }
      throw err;
    });

  return Observable.fromPromise(request);
}

export function getVaultedCardsApi(apiKey) {
  const baseUrl = ooeConstants.URL.PAYMENT;
  const url = `${baseUrl}/accounts/1.1/me`;

  const bugsnagInfo = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PAYMENTS,
    breadcrumb: 'Attempting to get vaulted cards',
    context: 'Get Vaulted Cards',
    info: {
      method: 'GET',
      baseUrl,
      url,
      apiKey: `******${apiKey?.slice?.(-6)}`,
    },
  };

  leaveBreadcrumb(bugsnagInfo.context, bugsnagInfo);

  const request = fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${apiKey}` },
  }).then((response) => handleErrors(response, bugsnagInfo));
  return Observable.fromPromise(request);
}

export function deleteCardApi(accountId, apiKey) {
  const baseUrl = ooeConstants.URL.PAYMENT;
  const url = `${baseUrl}/accounts/1.1/me/${accountId}`;

  const bugsnagInfo = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PAYMENTS,
    breadcrumb: 'Attempting to get delete payment card',
    context: 'Delete Card',
    info: {
      method: 'DELETE',
      baseUrl,
      url,
      apiKey: `******${apiKey?.slice?.(-6)}`,
      accountId,
    },
  };

  leaveBreadcrumb(bugsnagInfo.context, bugsnagInfo);

  const request = fetch(url, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${apiKey}` },
  }).then((response) => handleErrors(response, bugsnagInfo));
  return Observable.fromPromise(request);
}

export default {
  getPaymentiFrameParameters,
  addNewCardApi,
  getVaultedCardsApi,
  deleteCardApi,
};
