/* istanbul ignore next */
import { combineEpics } from 'redux-observable';

/* istanbul ignore next */
import OrderEpics from './order';
/* istanbul ignore next */
import PaymentEpics from './payment';
/* istanbul ignore next */
import GuestEpics from './guest';

/* istanbul ignore next */
export const epicsArray = [...OrderEpics, ...PaymentEpics, ...GuestEpics];
/* istanbul ignore next */
const combinedEpics = combineEpics(...epicsArray);

/* istanbul ignore next */
export default combinedEpics;
