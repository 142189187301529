import { Observable } from 'rxjs/Observable';
import ooeConstants from '../constants';
import handleErrors from '../util/handleErrors';
import { leaveBreadcrumb } from './bugsnag';

export function getGuestOAuth(idToken) {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/users/3.0/session?token=${idToken}`;

  const bugsnagInfo = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
    breadcrumb: 'Attempting to retrieve OAuth',
    context: 'Get Guest OAuth',
    info: {
      method: 'GET',
      baseUrl,
      url: `${url.substring(0, 96)}******`,
    },
  };

  leaveBreadcrumb(bugsnagInfo.context, bugsnagInfo);

  const request = fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  }).then((response) => handleErrors(response, bugsnagInfo));

  return Observable.fromPromise(request);
}

export function getGuestProfile(apiKey) {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/users/2.0/me`;

  const bugsnagInfo = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
    breadcrumb: 'Attempting to retrieve guest profile',
    context: 'Get Guest Profile',
    info: {
      method: 'GET',
      baseUrl,
      url,
      apiKey: `******${apiKey?.slice?.(-6)}`,
    },
  };

  leaveBreadcrumb(bugsnagInfo.context, bugsnagInfo);

  const request = fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => handleErrors(response, bugsnagInfo))
    .then(/* istanbul ignore next */ (res) => res.userProfile);

  return Observable.fromPromise(request);
}

export function convertGuestToCfaOne({ apiKey, password, sessionId, ipAddress }) {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/users/2.0/me/cfaOneCustomer`;
  const body = {
    password,
    sessionId,
    clientIPAddress: ipAddress,
  };

  const bugsnagInfo = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
    breadcrumb: 'Attempting to convert guest to Cfa One',
    context: 'Convert Guest To Cfa One',
    info: {
      method: 'POST',
      baseUrl,
      url,
      sessionId,
      apiKey: `******${apiKey?.slice?.(-6)}`,
    },
  };

  leaveBreadcrumb(bugsnagInfo.context, bugsnagInfo);

  const request = fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => handleErrors(response, bugsnagInfo));

  return Observable.fromPromise(request);
}

export default {
  getGuestOAuth,
  getGuestProfile,
  convertGuestToCfaOne,
};
