import { notifyBugsnag } from '../services/bugsnag';

const okayStatusCodes = [
  '000', // Profile API /users/2.0/me/cfaOneCustomer - creates the CFA One account
  '0000', // Gift Card API /giftcard/2.0/me/session - gets the payment frame URL
];

export default function handleErrors(
  response,
  bugsnagData = {
    errorClass: response.url || 'API Call Error',
    context: 'API Call Error',
    breadcrumb: '',
    info: {},
  },
) {
  if (!response.ok) {
    notifyBugsnag(bugsnagData.errorClass, {
      response,
      context: bugsnagData.context,
      breadcrumb: bugsnagData.breadcrumb,
      info: bugsnagData.info,
    });

    return Promise.reject(Error(response.statusText));
  }
  return response.json().then((responseJson) => {
    try {
      const { statusCode } = responseJson;
      const isInvalidStatus = statusCode && !okayStatusCodes.includes(statusCode);

      if (isInvalidStatus) {
        notifyBugsnag(error, {
          response,
          context: `API Call Invalid Status - ${bugsnagData.context}`,
          breadcrumb: bugsnagData.breadcrumb,
          info: {
            ...bugsnagData.info,
            statusCode,
            okayStatusCodes,
          },
        });

        throw Error(responseJson.reasonMessage);
      }
    } catch (error) {
      notifyBugsnag(error, {
        response,
        context: `API Call Error Parsing JSON - ${bugsnagData.context}`,
        breadcrumb: bugsnagData.breadcrumb,
        info: bugsnagData.info,
      });
    }

    return responseJson;
  });
}
