import styled from 'styled-components';

const SectionHeader = styled.h3`
  color: ${props => props.theme.primary};
  width: 100%;
  margin: 0;
  clear: both;
  font-size: 30px;
  font-weight: bold;
  @media(max-width: 500px) {
    font-size: 25px;
  }
`;

export default SectionHeader;
