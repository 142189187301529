import { pathOr } from 'ramda';

export default function errorCodeFromSuccess(configs) {
  const errorString = 'error&ResultCode=';
  const vaultUrl = pathOr('', ['vaultUrl'], configs);
  const error = vaultUrl.includes(errorString);
  if (error) {
    // Characters that follow the error string is the error code
    return configs.vaultUrl.split(errorString).pop();
  }
  return null;
}
