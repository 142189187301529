import {
  selectApiKey,
  selectCfaId,
  selectGuestDetails,
  selectAccountConverted,
  selectProgressSteps,
  selectConvertGuestIsLoading,
  selectGuestProfileLoading,
  selectGuestError,
  selectConvertGuestError,
  selectGuestFatalError,
} from '../reducers/guest';
import {
  selectOrder,
  selectOrderForDXE,
  selectOrderError,
  selectDestination,
  selectLocationNumber,
  selectLocationName,
  selectLocationPhone,
  selectDestinationDetails,
  selectSubTotalAmount,
  selectFormattedTotals,
  selectPotentialPoints,
  selectEventDetails,
  selectOrderIsLoading,
  selectSubmitPaymentIsLoading,
  selectDeliveryTipIsDisplayed,
  selectDeliveryTipLoading,
  selectSelectedPercentageTipValue,
  selectSelectedCustomTipValue,
  selectPaymentSubmitted,
} from '../reducers/order';
import {
  selectPayment,
  selectVaultedCardsIsLoading,
  selctAddCardIsLoading,
  selectIframeIsLoading,
  selectDeleteCardIsLoading,
  selectError,
  selectPaymentErrors,
  selectPaymentFrameError,
  selectVaultedCards,
} from '../reducers/payment';
import { maskEmail, maskName, maskPhone } from './utils';

const UNAVAILABLE = 'n/a';

function getOrderData(state) {
  const {
    clientId,
    user,
    status,
    appStatus,
    timestamps,
    timeZone,
    autoCheckIn,
    loyaltySource,
    processLoyalty,
    isAlohaLoyaltyDown,
    alohaLoyaltyDown,
  } = selectOrder(state);
  return {
    clientId,
    user,
    status,
    appStatus,
    timestamps,
    timeZone,
    autoCheckIn,
    loyaltySource,
    processLoyalty,
    isAlohaLoyaltyDown,
    alohaLoyaltyDown,
    order: selectOrderForDXE(state),
    orderError: selectOrderError(state),
    destination: selectDestination(state),
    locationNumber: selectLocationNumber(state),
    locationName: selectLocationName(state),
    locationPhone: selectLocationPhone(state),
    destinationDetails: selectDestinationDetails(state),
    subTotalAmount: selectSubTotalAmount(state),
    formattedTotals: selectFormattedTotals(state),
    potentialPoints: selectPotentialPoints(state),
    eventDetails: selectEventDetails(state),
    orderIsLoading: selectOrderIsLoading(state),
    submitPaymentIsLoading: selectSubmitPaymentIsLoading(state),
    deliveryTipIsDisplayed: selectDeliveryTipIsDisplayed(state),
    deliveryTipLoading: selectDeliveryTipLoading(state),
    selectedPercentageTipValue: selectSelectedPercentageTipValue(state),
    selectedCustomTipValue: selectSelectedCustomTipValue(state),
    paymentSubmitted: selectPaymentSubmitted(state),
  };
}

function getGuestData(state) {
  const guestData = {
    cfaOneId: selectCfaId(state),
    error: selectError(state),
    guestDetails: selectGuestDetails(state),
    accountConverted: selectAccountConverted(state),
    progressSteps: selectProgressSteps(state),
    convertGuestIsLoading: selectConvertGuestIsLoading(state),
    guestProfileLoading: selectGuestProfileLoading(state),
    guestError: selectGuestError(state),
    convertGuestError: selectConvertGuestError(state),
    guestFatalError: selectGuestFatalError(state),
    accessToken: `*****${selectApiKey(state)?.slice?.(-6) ?? UNAVAILABLE}`,
  };
  return guestData;
}

const getPaymentData = (state) => {
  const { submitted } = selectPayment(state);
  const paymentData = {
    submitted,
    vaultedCardsIsLoading: selectVaultedCardsIsLoading(state),
    addCardIsLoading: selctAddCardIsLoading(state),
    iframeIsLoading: selectIframeIsLoading(state),
    deleteCardIsLoading: selectDeleteCardIsLoading(state),
    error: selectError(state),
    paymentErrors: selectPaymentErrors(state),
    paymentFrameError: selectPaymentFrameError(state),
    vaultedCards: selectVaultedCards(state)?.map(x => ({
      updateAt: x.updateAt,
      paymentProcessedTime: x.paymentProcessedTime,
      cardType: x.cardType,
      accountDisplay: x.accountDisplay,
      countryCode: x.countryCode,
      type: x.type,
    })),
  };
  return paymentData;
};

const getRouterData = () => {
  const { origin, protocol, host, hostname, port, pathname } = window.location;
  return { origin, protocol, host, hostname, port, pathname };
};

const maskGuestDetails = (guestDetails = {}) => ({
  ...guestDetails,
  first: maskName(guestDetails?.first),
  last: maskName(guestDetails?.last),
  phone: maskPhone(guestDetails?.phone),
  email: maskEmail(guestDetails?.email),
});

const maskOrderUserDetails = (orderUserDetails = {}) => ({
  ...orderUserDetails,
  firstName: maskName(orderUserDetails?.firstName),
  phoneNumber: maskPhone(orderUserDetails?.phoneNumber),
});

export default function getBugSnagStateData(store) {
  let stateData;
  try {
    const state = store.getState();
    stateData = {
      guest: getGuestData(state),
      order: getOrderData(state),
      paymentData: getPaymentData(state),
      routerData: getRouterData(),
    };

    stateData.guest.guestDetails = maskGuestDetails(stateData.guest?.guestDetails);
    stateData.order.user = maskOrderUserDetails(stateData.order?.user);

    return stateData;
  } catch (error) {
    return {
      message: 'Unable to get session metadata',
      stateData,
      error,
    };
  }
}
