import styled from 'styled-components';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../assets/spinner.gif';

function ButtonSpinner({ className }) {
  return (
    <div className={className}>
      <img src={LoadingSpinner} alt="Loading spinner" className="spinner-img" />
    </div>
  );
}

const StyledButtonSpinner = styled(ButtonSpinner)`
    border: 1px solid ${props => props.theme.disabled};
    border-radius: 24px;
    color: #fff;
    width: ${props => props.width};
    text-align: center;
    & .spinner-img {
      width: 34px;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
`;

ButtonSpinner.propTypes = {
  className: PropTypes.string.isRequired,
};

export default StyledButtonSpinner;
