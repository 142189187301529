import { createSelector } from 'reselect';
import { isEmpty, pathOr } from 'ramda';
import { types as orderTypes } from './order';
import ooeConstants from '../constants';

export const types = {
  GET_GUEST_SESSION: '[Guest] Get OAuth session',
  GET_GUEST_SESSION_SUCCESS: '[Guest] Get OAuth session success',
  GET_GUEST_SESSION_FAILURE: '[Guest] Get OAuth session failure',
  GET_GUEST_PROFILE_SUCCESS: '[Guest] Get profile success',
  GET_GUEST_PROFILE_FAILURE: '[Guest] Get profile failure',
  CONVERT_GUEST: '[Guest] Convert account',
  CONVERT_GUEST_SUCCESS: '[Guest] Convert account success',
  CONVERT_GUEST_FAILURE: '[Guest] Convert account failure',
  CHECKOUT_AS_GUEST: '[Guest] Checkout as guest',
  GUEST_PAYMENT_SUCCESS: '[Guest] Payment Success',
  GUEST_PAYMENT_FAILURE: '[Guest] Payment Failure',
  DELETE_CARD: '[Guest] Delete Vaulted card',
  DELETE_CARD_SUCCESS: '[Guest] Delete Vaulted card success',
  DELETE_CARD_FAILURE: '[Guest] Delete Vaulted card failure',
  NO_HASH_ERROR: '[Guest] No Hash Error',
  STEP_COMPLETED: '[Order] Step Completed',
};

export const actions = {
  getGuestOauthSession: () => ({ type: types.GET_GUEST_SESSION }),
  getGuestOauthSessionSuccess: (session) => ({ type: types.GET_GUEST_SESSION_SUCCESS, session }),
  getGuestOauthSessionFailure: (error) => ({ type: types.GET_GUEST_SESSION_FAILURE, error }),
  getGuestProfileSuccess: (profile) => ({ type: types.GET_GUEST_PROFILE_SUCCESS, profile }),
  getGuestProfileFailure: (error) => ({ type: types.GET_GUEST_PROFILE_FAILURE, error }),
  convertGuest: (password) => ({ type: types.CONVERT_GUEST, password }),
  convertGuestSuccess: () => ({ type: types.CONVERT_GUEST_SUCCESS }),
  convertGuestFailure: (error) => ({ type: types.CONVERT_GUEST_FAILURE, error }),
  checkoutAsGuest: () => ({ type: types.CHECKOUT_AS_GUEST }),
  noHashError: () => ({ type: types.NO_HASH_ERROR }),
  stepCompleted: (step) => ({ type: types.STEP_COMPLETED, step }),
};

export const initialState = {
  isOriginalCfaOneGuest: false,
  isConvertedCfaOneGuest: false,
  loading: { profile: true },
  profile: {},
  progressSteps: [
    {
      value: 1,
      title: 'Order Summary',
      path: '/',
      completed: false,
    },
    {
      value: 2,
      title: 'Create an Account',
      path: '/guestLogin/',
      completed: false,
    },
    {
      value: 3,
      title: 'Add Payment and Place Order',
      path: '/payment/',
      completed: false,
    },
    {
      value: 4,
      title: 'All Done',
      path: '/success/',
      completed: false,
    },
  ],
};

const { GENERIC, NO_TOKEN } = ooeConstants;

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GUEST_SESSION_SUCCESS: {
      const { session } = action;
      const apiKey = session.access_token;
      return { ...state, apiKey };
    }

    case types.GET_GUEST_SESSION_FAILURE:
      return {
        ...state,
        fatalError: GENERIC,
      };

    case types.NO_HASH_ERROR:
      return {
        ...state,
        fatalError: NO_TOKEN,
      };

    case types.GET_GUEST_PROFILE_SUCCESS: {
      const { profile } = action;
      let { progressSteps, isOriginalCfaOneGuest } = state;
      if (profile && profile.customerType === 'customers') {
        isOriginalCfaOneGuest = true;
        progressSteps = [
          {
            value: 1,
            title: 'Order Summary',
            path: '/',
            completed: false,
          },
          {
            value: 2,
            title: 'Select Payment and Place Order',
            path: '/payment/',
            completed: false,
          },
          {
            value: 3,
            title: 'All Done',
            path: '/success/',
            completed: false,
          },
        ];
      }
      return {
        ...state,
        profile,
        isOriginalCfaOneGuest,
        progressSteps,
        loading: { ...state.loading, profile: false },
        error: {
          ...state.error,
        },
      };
    }

    case types.GET_GUEST_PROFILE_FAILURE: {
      return {
        ...state,
        loading: { profile: false },
      };
    }

    case types.CONVERT_GUEST: {
      return {
        ...state,
        loading: { ...state.loading, convertGuest: true },
      };
    }

    case types.CONVERT_GUEST_SUCCESS: {
      const { progressSteps } = state;
      const updateStepStatus = progressSteps.map((thisStep) => {
        if (thisStep.value === 2) {
          return { ...thisStep, completed: true };
        }
        return thisStep;
      });
      return {
        ...state,
        loading: { ...state.loading, convertGuest: false },
        progressSteps: updateStepStatus,
        isConvertedCfaOneGuest: true,
      };
    }

    case types.CONVERT_GUEST_FAILURE: {
      return {
        ...state,
        error: {
          ...state.error,
          convertGuest: ooeConstants.convertGuestError,
        },
        loading: { ...state.loading, convertGuest: false },
      };
    }

    case types.STEP_COMPLETED: {
      const { progressSteps } = state;
      const { step } = action;
      const updateStepStatus = progressSteps.map((thisStep) => {
        if (thisStep.value === step) {
          return { ...thisStep, completed: true };
        }
        return thisStep;
      });
      return {
        ...state,
        progressSteps: updateStepStatus,
      };
    }

    case orderTypes.SUBMIT_ORDER_SUCCESS: {
      const { progressSteps } = state;
      const updateStepStatus = progressSteps.map((thisStep) => ({
        ...thisStep,
        completed: true,
        disabled: true,
      }));
      return {
        ...state,
        progressSteps: updateStepStatus,
      };
    }

    default:
      return state;
  }
};

export const selectGuest = (state) => state.guest || {};
export const selectGuestError = createSelector(selectGuest, (guest) => guest.error);
export const selectConvertGuestError = createSelector(selectGuestError, (error) =>
  pathOr('', ['convertGuest'], error),
);
export const selectGuestFatalError = createSelector(selectGuest, (guest) => {
  if (guest.fatalError === NO_TOKEN) {
    return ooeConstants.noTokenError;
  }
  if (guest.fatalError === GENERIC) {
    return ooeConstants.genericOrderError;
  }
  return null;
});
export const selectGuestProfile = createSelector(selectGuest, (guest) => guest.profile || {});
export const selectGuestProfileLoading = createSelector(
  selectGuest,
  (guest) => guest.loading.profile,
);
export const selectCfaId = createSelector(selectGuestProfile, (profile) => profile.uid);
export const selectGuestDetails = createSelector(selectGuestProfile, (profile) => {
  if (isEmpty(profile)) {
    return profile;
  }
  const { name, emails, phoneNumbers } = profile;
  const email = pathOr('', [0, 'value'], emails);
  const phone = pathOr('', [0, 'value'], phoneNumbers);
  const first = pathOr('', ['givenName'], name);
  const last = pathOr('', ['familyName'], name);

  return {
    first,
    last,
    email,
    phone,
  };
});
export const selectApiKey = createSelector(selectGuest, (guest) => guest.apiKey || '');
export const selectShowPasswordPrompt = createSelector(
  selectGuest,
  (guest) => !guest.isOriginalCfaOneGuest,
);
export const selectGuestName = createSelector(selectGuest, (guest) => {
  if (guest.isOriginalCfaOneGuest || guest.isConvertedCfaOneGuest) {
    return pathOr('', ['profile', 'name', 'givenName'], guest);
  }
  return '';
});
export const selectAccountConverted = createSelector(
  selectGuest,
  (guest) => guest.isConvertedCfaOneGuest,
);
export const selectConvertGuestIsLoading = createSelector(
  selectGuest,
  (guest) => guest.loading.convertGuest,
);
export const selectProgressSteps = createSelector(selectGuest, (guest) => guest.progressSteps);
