import styled from 'styled-components';
import PropTypes from 'prop-types';
import Column from '../Column/Column';

function SocialIcons({ className }) {
  return (
    <StyledSocialIcons className={className} xs="12" sm="12" md="12" lg="3">
      <a href="https://www.facebook.com/ChickfilA/" target="_blank" rel="noopener noreferrer">
        <span aria-hidden data-icon="&#xe901;" />
      </a>
      <a href="https://www.instagram.com/chickfila" target="_blank" rel="noopener noreferrer">
        <span aria-hidden data-icon="&#xe900;" />
      </a>
      <a href="https://twitter.com/ChickfilA" target="_blank" rel="noopener noreferrer">
        <span aria-hidden data-icon="&#xe912;" />
      </a>
      <a href="https://www.youtube.com/user/chickfila" target="_blank" rel="noopener noreferrer">
        <span aria-hidden data-icon="&#xe913;" />
      </a>
      <a href="https://www.linkedin.com/company/chick-fil-a-corporate/careers?trk=top_nav_careers" target="_blank" rel="noopener noreferrer">
        <span aria-hidden data-icon="&#xe91c;" />
      </a>
      <a href="http://chickfila.tumblr.com/" target="_blank" rel="noopener noreferrer">
        <span aria-hidden data-icon="&#xe905;" />
      </a>
    </StyledSocialIcons>
  );
}

SocialIcons.propTypes = {
  className: PropTypes.string,
};

SocialIcons.defaultProps = {
  className: '',
};

const StyledSocialIcons = styled(Column)`
  padding-top: 25px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  & [data-icon]:before {
    font-family: 'icomoon';
    content: attr(data-icon);
    font-size: 30px;
    padding: 5px;
    color: ${props => props.theme.tertiary};
  }
  @media(max-width: 900px) {
    justify-content: center;
    margin: 20px auto;
    padding-top: 0;
  }
`;

export default SocialIcons;
