/* eslint-disable */
import 'react-app-polyfill/ie9';

// RXJS Imports
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/switch';
import 'rxjs/add/operator/take';

import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import './index.css';
import ConnectedApp from './App';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './configureStore';
import ooeConstants from './constants';

import getBugSnagStateData from './util/bugSnagStateData';
import {
  setupBugsnag,
  leaveBreadcrumb,
  initializeGetBugSnagStateData,
} from './services/bugsnag';
import { BugsnagErrorBoundary, UnrecoverableError } from './components';

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory({
  basename: '/payment/',
});

const store = configureStore(history);

// useful for debugging deployed environments
/* eslint-disable no-console */
console.debug('__/__/__/__/__/__/__/__/__/__/__/__/__/__/__/');
console.debug(`process.env.NODE_ENV=${process.env?.NODE_ENV}`);
console.debug(`bugsnag release stage=${ooeConstants.BUGSNAG_RELEASE_STAGE}`);
console.debug('process.env=', process.env);
console.debug('window.location=', window.location);
console.debug('__/__/__/__/__/__/__/__/__/__/__/__/__/__/__/');
console.debug('');
/* eslint-enable no-console */

// set up bugsnag
initializeGetBugSnagStateData(getBugSnagStateData);
setupBugsnag(store);

leaveBreadcrumb('Application Startup', {
  message: 'Application initializing',
});

const render = (Component) => {
  ReactDOM.render(
    <BugsnagErrorBoundary FallbackComponent={UnrecoverableError}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Component />
        </ConnectedRouter>
      </Provider>
    </BugsnagErrorBoundary>,
    document.getElementById('root'),
  );
};

render(ConnectedApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    render(ConnectedApp);
  });
}
registerServiceWorker();
