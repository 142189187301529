import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Section from '../components/Section/Section';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import Overlay from '../components/Overlay/Overlay';
import Error from '../components/Error/Error';
import VaultedCards from '../components/PaymentOptions/VaultedCards';
import { selectShowPasswordPrompt, actions as guestActions } from '../reducers/guest';
import {
  actions as orderActions,
  selectDeliveryTipIsDisplayed,
  selectFormattedTotals,
  selectPaymentSubmitted,
  selectSubmitPaymentIsLoading,
} from '../reducers/order';
import {
  actions as paymentActions, selectSelectedAccount,
  selctAddCardIsLoading, selectIframeIsLoading, selectDeleteCardIsLoading,
  selectVaultedCardsIsLoading, selectVaultedCards, selectPaymentFrameConfigs,
  selectPaymentFrameError,
} from '../reducers/payment';
import { selectFullPaymentErrorMessages } from '../reducers';
import AddNewCardModal from '../components/AddNewCardModal/AddNewCardModal';
import { writeScriptTag, removeScriptTag } from '../util/writeAndRemoveScriptTags';
import ooeConstants from '../constants';

export class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      paymentScriptAttachedToDom: '',
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.initializePaymentSDK = this.initializePaymentSDK.bind(this);
    this.handleAddPaymentSuccess = this.handleAddPaymentSuccess.bind(this);
    Modal.setAppElement('body');
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  componentDidUpdate(prevProps) {
    const { paymentFrameError, paymentFrameConfigs } = this.props;
    if ((prevProps.paymentFrameError !== paymentFrameError) && paymentFrameError) {
      this.closeModal();
    }
    if ((prevProps.paymentFrameConfigs !== paymentFrameConfigs) && paymentFrameConfigs) {
      const { paymentScriptAttachedToDom } = this.state;
      if (!paymentScriptAttachedToDom) {
        const { javascriptSdk } = paymentFrameConfigs;
        writeScriptTag(javascriptSdk).then(() => {
          this.setState({ paymentScriptAttachedToDom: javascriptSdk });
          this.initializePaymentSDK(paymentFrameConfigs);
        });
      } else {
        this.initializePaymentSDK(paymentFrameConfigs);
      }
    }
  }

  componentWillUnmount() {
    const { paymentScriptAttachedToDom } = this.state;
    removeScriptTag(paymentScriptAttachedToDom);
  }

  handleAddPaymentSuccess({ configs, response }) {
    const { addCard } = this.props;
    const enrichedResponse = {
      ...JSON.parse(response),
      accessToken: configs.accessToken,
      correlationId: configs.correlationID,
      source: 'CFA',
    };
    addCard(enrichedResponse);
    this.closeModal();
  }

  initializePaymentSDK(paymentFrameConfigs) {
    const extraConfigs = {
      ...paymentFrameConfigs,
      mountId: 'ucom-container',
      debug: process.env.NODE_ENV !== ooeConstants.PRODUCTION,
    };
    window.ucomSDK.init(extraConfigs, (response) => {
      window.ucomSDK.stop();
      this.handleAddPaymentSuccess({ configs: paymentFrameConfigs, response });
    });
    window.ucomSDK.start();
  }

  openModal() {
    const { getPaymentFrame } = this.props;
    window.scrollTo(0, 0);
    getPaymentFrame();
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    const { paymentScriptAttachedToDom } = this.state;
    this.setState({ modalIsOpen: false });
    if (paymentScriptAttachedToDom) {
      window.ucomSDK.stop();
    }
  }

  render() {
    const {
      vaultedCards,
      accountSelected,
      selectedAccount,
      deleteCard,
      isDeleteCardLoading,
      isVaultedCardsLoading,
      isAddCardLoading,
      isIframeLoading,
      paymentErrors,
      submitOrder,
      isSubmitPaymentLoading,
      formattedTotals,
      deliveryTipIsDisplayed,
    } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <Section>
        <SectionHeader>Select your payment method</SectionHeader>
        {modalIsOpen && isIframeLoading ? <Overlay /> : (
          <AddNewCardModal
            modalIsOpen={modalIsOpen}
            closeModal={this.closeModal}
          />)}
        { paymentErrors && paymentErrors.map(error => <Error key={error} text={error} />) }
        <VaultedCards
          vaultedCards={vaultedCards}
          accountSelected={accountSelected}
          selectedAccount={selectedAccount}
          openModal={this.openModal}
          deleteCard={card => deleteCard(card)}
          deleteCardLoading={isDeleteCardLoading}
          vaultedCardsLoading={isVaultedCardsLoading}
          addCardLoading={isAddCardLoading}
          submitOrder={submitOrder}
          isSubmitPaymentLoading={isSubmitPaymentLoading}
          totals={formattedTotals}
          deliveryTipIsDisplayed={deliveryTipIsDisplayed}
        />
      </Section>
    );
  }
}

Payment.propTypes = {
  paymentFrameConfigs: PropTypes.objectOf(PropTypes.any),
  addCard: PropTypes.func.isRequired,
  vaultedCards: PropTypes.arrayOf(PropTypes.object).isRequired,
  accountSelected: PropTypes.func.isRequired,
  selectedAccount: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
    PropTypes.bool,
    PropTypes.object,
  ])).isRequired,
  deleteCard: PropTypes.func.isRequired,
  isDeleteCardLoading: PropTypes.bool,
  isVaultedCardsLoading: PropTypes.bool,
  isAddCardLoading: PropTypes.bool,
  isIframeLoading: PropTypes.bool,
  getPaymentFrame: PropTypes.func,
  paymentErrors: PropTypes.arrayOf(PropTypes.string),
  paymentFrameError: PropTypes.string,
  submitOrder: PropTypes.func,
  isSubmitPaymentLoading: PropTypes.bool,
  formattedTotals: PropTypes.objectOf(PropTypes.string).isRequired,
  deliveryTipIsDisplayed: PropTypes.bool,
};

Payment.defaultProps = {
  paymentFrameConfigs: {},
  isDeleteCardLoading: false,
  isVaultedCardsLoading: true,
  isAddCardLoading: false,
  isIframeLoading: false,
  getPaymentFrame: () => {},
  paymentErrors: [],
  paymentFrameError: '',
  submitOrder: () => {},
  isSubmitPaymentLoading: false,
  deliveryTipIsDisplayed: false,
};

function mapStateToProps(state) {
  return {
    showPasswordPrompt: selectShowPasswordPrompt(state),
    paymentFrameConfigs: selectPaymentFrameConfigs(state),
    formattedTotals: selectFormattedTotals(state),
    submitted: selectPaymentSubmitted(state),
    vaultedCards: selectVaultedCards(state),
    selectedAccount: selectSelectedAccount(state),
    isDeleteCardLoading: selectDeleteCardIsLoading(state),
    isVaultedCardsLoading: selectVaultedCardsIsLoading(state),
    isAddCardLoading: selctAddCardIsLoading(state),
    isIframeLoading: selectIframeIsLoading(state),
    paymentErrors: selectFullPaymentErrorMessages(state),
    paymentFrameError: selectPaymentFrameError(state),
    isSubmitPaymentLoading: selectSubmitPaymentIsLoading(state),
    deliveryTipIsDisplayed: selectDeliveryTipIsDisplayed(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...guestActions, ...paymentActions, ...orderActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
