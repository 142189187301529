import styled from 'styled-components';

function getWidthString(span) {
  /* istanbul ignore if */
  if (!span) return '';
  const width = (span / 12) * 100;
  return `width: ${width}%;`;
}
const Column = styled.div`
  float: left;
  text-align: center;
  ${props => props.xs && getWidthString(props.xs)};
  
  @media (min-width: 500px) {
    ${props => props.sm && getWidthString(props.sm)};
  }  
  @media (min-width: 750px) {
    ${props => props.md && getWidthString(props.md)};
  }
  @media (min-width: 900px) {
    ${props => props.lg && getWidthString(props.lg)};
  }
`;

export default Column;
