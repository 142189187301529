import styled from 'styled-components';
import footerDesktop from '../../assets/footer-desktop.jpg';
import footerMobile from '../../assets/footer-mobile.jpg';

const StyledBottomHero = styled.div`
  font-family: ${props => props.theme.fontFamily};
  background-image: url(${footerDesktop});
  background-size: cover;
  background-position: top;
  color: white;
  height: 400px;
  clear: both;
  margin: 50px 0 30px;

  @media(max-width: 500px) {
    background-image: url(${footerMobile});
    height: 700px;
  }
`;

export default StyledBottomHero;
