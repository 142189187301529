import { Observable } from 'rxjs/Observable';
import handleErrors from '../util/handleErrors';
import ooeConstants from '../constants';
import { leaveBreadcrumb } from './bugsnag';

export function getOrder(apiKey, orderId) {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${orderId}`;

  const bugsnagInfo = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
    breadcrumb: `Attempting to retrieve order ${orderId}`,
    context: 'Get Order',
    info: {
      method: 'GET',
      baseUrl,
      url,
      orderId,
      apiKey: `******${apiKey?.slice?.(-6)}`,
    },
  };

  leaveBreadcrumb(bugsnagInfo.context, bugsnagInfo);

  const request = fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    },
  }).then((response) => handleErrors(response, bugsnagInfo));

  return Observable.fromPromise(request);
}

export function lookupLocationsByNumber(locationNumber, apiKey) {
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/4.0/search?locationNumbers=${locationNumber}`;

  const bugsnagInfo = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
    breadcrumb: `Attempting to lookup locations by number for location "${locationNumber}"`,
    context: 'Lookup Locations By Number',
    info: {
      method: 'GET',
      baseUrl,
      url,
      locationNumber,
    },
  };

  leaveBreadcrumb(bugsnagInfo.context, bugsnagInfo);

  const request = fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    },
  }).then((response) => handleErrors(response, bugsnagInfo));
  return Observable.fromPromise(request);
}

export function submitOrder(apiKey, order) {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/1.0/${order.id}`;

  const bugsnagInfo = {
    errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
    breadcrumb: `Attempting to submit order ${order.id}`,
    context: 'Submit Order',
    info: {
      method: 'PUT',
      baseUrl,
      url,
      order,
      apiKey: `******${apiKey?.slice?.(-6)}`,
    },
  };

  leaveBreadcrumb(bugsnagInfo.context, bugsnagInfo);

  const request = fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(order),
  }).then((response) => handleErrors(response, bugsnagInfo));

  return Observable.fromPromise(request);
}

export default {
  getOrder,
  lookupLocationsByNumber,
  submitApiPayment: submitOrder,
};
