/* istanbul ignore next */
import { combineReducers } from 'redux';
/* istanbul ignore next */
import { createSelector } from 'reselect';
/* istanbul ignore next */
import payment, { actions as paymentActions, selectPaymentErrors } from './payment';
/* istanbul ignore next */
import order, {
  actions as orderActions,
  selectLocationName,
  selectLocationPhone,
  selectOrderError,
  selectOrderIsLoading,
} from './order';
/* istanbul ignore next */
import guest, {
  actions as guestActions,
  selectGuestFatalError,
  selectGuestProfileLoading,
} from './guest';
// import ooeConstants from '../constants';

/* istanbul ignore next */
export default combineReducers({
  order,
  payment,
  guest,
});

/* istanbul ignore next */
export const combinedActionCreators = {
  ...paymentActions,
  ...orderActions,
  ...guestActions,
};

export const selectFatalError = createSelector(
  selectOrderError,
  selectGuestFatalError,
  (orderError, guestFatalError) => orderError || guestFatalError,
);

export const selectAppLoading = createSelector(
  selectGuestProfileLoading,
  selectOrderIsLoading,
  (guestLoading, orderLoading) => guestLoading || orderLoading,
);

export const selectFullPaymentErrorMessages = createSelector(
  selectPaymentErrors,
  selectLocationName,
  selectLocationPhone,
  (errors, locationName, locationNumber) =>
    errors.map(
      (err) =>
        `${err} If you are still experiencing difficulties contact ${locationName} at ${locationNumber}.`,
    ),
);
