import styled from 'styled-components';
import dots from '../../assets/dots.svg';

const SectionHeader = styled.div`
  margin: 30px 0 30px -10%;
  width: 120%;
  clear: both;
  border-style: dotted;
  border-image-source: url(${dots});
  border-image-slice: 33% 25%;
  border-image-repeat: round;
  border-width: 0 0 5px 0;
  @media (max-width: 600px) {
    margin: 30px 0 30px -5%;
    width: 110%;
  }
`;

export default SectionHeader;
