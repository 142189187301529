import { of } from 'rxjs/observable/of';
import { push } from 'connected-react-router';
import { actions as orderActions, selectOrderForDXE, types as orderTypes } from '../reducers/order';
import { selectApiKey, types as guestTypes } from '../reducers/guest';
import { getOrder, lookupLocationsByNumber, submitOrder } from '../services/orderApi';
import { getHash } from '../util/location';
import { selectPaymentForDXE } from '../reducers/payment';

export const GetOrder = (action$, store) =>
  action$
    .ofType(guestTypes.GET_GUEST_SESSION_SUCCESS)
    .map(getHash)
    .filter((hash) => hash.id_token && hash.id_token.length > 0)
    .switchMap((hash) => {
      const state = store.getState();
      const apiKey = selectApiKey(state);

      // TODO consider getting this through state so we can remove hash from url
      const token = hash.id_token;
      const payload = JSON.parse(atob(token.split('.')[1]));
      return getOrder(apiKey, payload.orderId)
        .map(orderActions.getOrderSuccess)
        .catch((err) => of(orderActions.getOrderFailure(err)));
    });

export const GetLocation = (action$, store) =>
  action$
    .ofType(guestTypes.GET_GUEST_SESSION_SUCCESS)
    .map(getHash)
    .filter((hash) => hash.id_token && hash.id_token.length > 0)
    .switchMap((hash) => {
      const state = store.getState();
      const apiKey = selectApiKey(state);
      const token = hash.id_token;
      const payload = JSON.parse(atob(token.split('.')[1]));
      const { locationNumber } = payload;
      return lookupLocationsByNumber(locationNumber, apiKey)
        .map(orderActions.getLocationSuccess)
        .catch((err) => of(orderActions.getLocationFailure(err)));
    });

export const FatalError = (action$) =>
  action$.ofType(orderTypes.FATAL_ERROR).map(() =>
    push({
      pathname: '/error',
    }),
  );

export const AddDeliveryTip = (action$, store) =>
  action$.ofType(orderTypes.ADD_DELIVERY_TIP)
    .switchMap(({ deliveryTipType, deliveryTipValue }) => {
      const state = store.getState();
      const order = selectOrderForDXE(state);
      const apiKey = selectApiKey(state);
      const deliveryTip = {
        [deliveryTipType]: deliveryTipValue,
      };
      const orderToPost = {
        ...order,
        deliveryTip,
        status: 'Create',
      };
      return submitOrder(apiKey, orderToPost)
        .map(orderActions.addDeliveryTipSuccess)
        .catch((err) => of(orderActions.addDeliveryTipFailure(err)));
    });

export const SubmitOrder = (action$, store) =>
  action$.ofType(orderTypes.SUBMIT_ORDER).switchMap(() => {
    const state = store.getState();
    const order = selectOrderForDXE(state);
    const payment = selectPaymentForDXE(state);
    const apiKey = selectApiKey(state);
    const orderToPost = {
      ...order,
      payment,
    };
    return submitOrder(apiKey, orderToPost)
      .map(orderActions.submitOrderSuccess)
      .catch((err) => of(orderActions.submitOrderFailure(err)));
  });

export const GoToSuccessPage = (action$) =>
  action$.ofType(orderTypes.SUBMIT_ORDER_SUCCESS).map(() => push({ pathname: '/success' }));

export default [GetOrder, GetLocation, FatalError, SubmitOrder, GoToSuccessPage, AddDeliveryTip];
