export default class ooeConstants {
  static get DELIVERY() {
    return 'Delivery';
  }

  static get PICKUP() {
    return 'Pickup';
  }

  static get DEV() {
    return 'dev';
  }

  static get QA() {
    return 'qa';
  }

  static get PROD() {
    return 'prod';
  }

  static get DEVELOPER() {
    return 'developer';
  }

  static get DEVELOPMENT() {
    return 'development';
  }

  static get PRODUCTION() {
    return 'production';
  }

  /* istanbul ignore next */
  static get APP_VERSION() {
    const version = process?.env?.REACT_APP_VERSION ?? '0.0.0';
    const fullVersion = `${version}${process.env.NODE_ENV === this.DEVELOPMENT ? '-development' : ''}`;
    return fullVersion;
  }

  /* istanbul ignore next */
  static get BUGSNAG_ENABLED() {
    if (process.env.NODE_ENV !== this.DEVELOPMENT) {
      // eslint-disable-next-line no-console
      console.debug('NOT development mode, setting bugsnag enabled to true');
      return true;
    }

    const enableBugsnagInDevelopment = process.env?.REACT_APP_ENABLE_BUGSNAG_IN_DEVELOPMENT?.toLowerCase?.() === 'true';

    // eslint-disable-next-line no-console
    console.debug(`In development mode, enableBugsnagInDevelopment=${enableBugsnagInDevelopment}`);

    return !!enableBugsnagInDevelopment;
  }

  static get BUGSNAG_RELEASE_STAGE() {
    if (process.env.NODE_ENV !== ooeConstants.PRODUCTION || window.location?.host?.startsWith('localhost')) {
      // eslint-disable-next-line no-console
      console.debug(`BUGSNAG_RELEASE_STAGE returning this.DEVELOPER - ${this.DEVELOPER}`);
      return this.DEVELOPER;
    }

    // example: https://my.qa.crndev.chick-fil-a.com/payment/#id_token=eyJhbGc...
    if (window.location?.hostname?.startsWith('my.qa.')) {
      // eslint-disable-next-line no-console
      console.debug(`BUGSNAG_RELEASE_STAGE returning this.QA - ${this.QA}`);
      return this.QA;
    }

    // eslint-disable-next-line no-console
    console.debug(`BUGSNAG_RELEASE_STAGE returning PRODUCTION - ${ooeConstants.DEVELOPER}`);
    return ooeConstants.PRODUCTION;
  }

  /* istanbul ignore next */
  static get BUGSNAG_ATOB() {
    return atob('Nzc5NWM3NGExMTFlZDhjY2ZiMDczZTNjZmMyNjg3ZGU=');
  }

  static get BUGSNAG_ERRORCLASS_ORDERS() {
    return 'Orders API';
  }

  static get BUGSNAG_ERRORCLASS_PROFILE() {
    return 'Profile API';
  }

  static get BUGSNAG_ERRORCLASS_PAYMENTS() {
    return 'Payments API';
  }

  static get SUMMARY() {
    return '/summary';
  }

  static get THIRTY_MINUTES() {
    return 1800000;
  }

  static get ENVIRONMENT_DATA() {
    const { hostname } = window.location;
    let environment = 'qa';
    let pilotFlag = '';
    let pilot = false;

    const rx = /(qa|dev|prod)?(-pilot)?\.?(my|order-management|crndev)\.?(chick-fil-a|cfahome)\.com.*/g;
    const getEnv = rx.exec(hostname);

    if (getEnv) {
      [, environment, pilotFlag] = getEnv;
      pilot = pilotFlag === '-pilot';
      environment = environment || 'prod';
    }
    return { ENV: environment, IS_PILOT: pilot };
  }

  static get ENV() {
    return this.ENVIRONMENT_DATA.ENV;
  }

  static get DXE_SERVICES_BASE() {
    if (this.ENV === this.PROD) {
      return 'api.my.chick-fil-a.com';
    }
    return `api.${this.ENV}.crndev.chick-fil-a.com`;
  }

  static get URL() {
    let pilot = '';
    if (this.ENVIRONMENT_DATA.IS_PILOT) {
      pilot = '-pilot';
    }

    return {
      ORDERS: `https://order${pilot}.${this.DXE_SERVICES_BASE}/orders`,
      PROFILE: `https://profile${pilot}.${this.DXE_SERVICES_BASE}`,
      PAYMENT: `https://gc${pilot}.${this.DXE_SERVICES_BASE}`,
      LOCATIONS: `https://location${pilot}.${this.DXE_SERVICES_BASE}`,
    };
  }

  static genericOrderError(restaurantName, restaurantNumber) {
    if (restaurantName && restaurantNumber) {
      return `Oops! There was an error retrieving your order details. Please call ${restaurantName} at ${restaurantNumber}.`;
    }
    return 'Oops! There was an error retrieving your order details. Please call the restaurant you placed your order through.';
  }

  static noTokenError() {
    return 'Oops! There was an error retrieving your order details. Please try clicking the “Enter Payment Information” link in the email you received again or call the restuarant you placed your order through.';
  }

  static unrecoverableError() {
    return 'There was an unrecoverable error. Please try clicking the “Enter Payment Information” link in the email you received again or call the restuarant you placed your order through.';
  }

  static get convertGuestError() {
    return 'Oops! There was an error creating your account. Please try guest checkout.';
  }

  static get timeoutError() {
    return 'Your session timed out and you have been logged out for security reasons. Please return to the email you received and click the link again.';
  }

  static submitOrderError(restaurantName, restaurantNumber) {
    return `Oops! Your order was unable to be submitted at this time. Please try again or contact ${restaurantName} at ${restaurantNumber}.`;
  }

  static alreadySubmittedError(restaurantName, restaurantNumber) {
    return `Oops! It looks like payment for your order has already been submitted! Contact ${restaurantName} at ${restaurantNumber} if you have questions.`;
  }

  static cancelOrderError(restaurantName, restaurantNumber) {
    return `Oops! You are unable to add payment information to this order because the order has been cancelled. Please try again or contact ${restaurantName} at ${restaurantNumber}.`;
  }

  static get addDeliveryTipError() {
    return 'Oops! You are unable to add delivery tip to this order. Please try again';
  }

  static get GENERIC() {
    return 'generic';
  }

  static get SUBMIT_ORDER() {
    return 'submit order';
  }

  static get NO_TOKEN() {
    return 'no token';
  }

  static get ALREADY_SUBMITTED() {
    return 'already submitted';
  }

  static get CANCELLED_ORDER() {
    return 'cancelled order';
  }

  static get ORDER_STATUS() {
    return {
      Submit: 'Submit',
      Cancelled: 'Cancelled',
    };
  }

  static get SAUCES() {
    return [
      '8OZ_BARBEQUE_SAUCE',
      '8OZ_POLYNESIAN',
      '8OZ_BUFFALO_SAUCE',
      '8OZ_RANCH',
      '8OZ_HONEY_MUSTARD',
      '8OZ_CFA_SAUCE',
      '8OZ_SRIRACHA',
      'BBQ_INDIVIDUAL',
      'POLYNESIAN_INDIVIDUAL',
      'BUFFALO_INDIVIDUAL',
      'RANCH_INDIVIDUAL',
      'HONEY_MUSTARD_INDIVIDUAL',
      'CFA_SAUCE_INDIVIDUAL',
      'SRIRACHA_INDIVIDUAL',
    ];
  }

  static get DRESSINGS() {
    return [
      'RANCH_AVOCADO_LIME',
      'RANCH_GARLIC_HERB',
      'CREAMY_SALSA',
      'VINAIGRETTE_APPLE_CIDER',
      'HONEY_MUSTARD_FAT_FREE',
      'VINAIGRETTE_BALSAMIC_LITE',
      'ITALIAN_LITE',
      'VINAIGRETTE_CHILI_LIME',
    ];
  }

  static get ADD_ON_ITEMS() {
    return [
      'BACON',
      'CHEESE_COLBY_JACK',
      'PICKLES',
      'MULTIGRAIN_BUN',
      'CHEESE_AMERICAN',
      'CHEESE_PEPPER_JACK',
    ];
  }

  static get FIRST_DATA_ERROR_CODES() {
    return {
      1202: 'Oops! We are unable to verify your card information with your bank. Please check your card number, zip code, and CVV and try again.',
    };
  }

  static get PAYMENT_ERROR_MESSAGES() {
    return {
      DEFAULT: 'Oops! Something went wrong, please try again.',
      PAYMENT_FRAME: 'Oops! There was a problem adding new payment methods, please try again.',
      VAULTED_CARDS:
        'Oops! There was an error in retrieving your stored payment information. Please add a new payment method.',
      DELETE_CARD: 'Oops! There was an error deleting your payment method, please try again.',
    };
  }

  static get allowedCardTypes() {
    return ['VISA', 'MASTERCARD', 'AMEX', 'DISCOVER', 'JCB', 'DINERS'];
  }

  static get PATH_NAME() {
    return {
      SUMMARY: '/payment/',
      GUEST_LOGIN: '/payment/guestLogin/',
      PAYMENT: '/payment/payment/',
      ERROR: '/payment/error',
      SUCCESS: '/payment/success',
    };
  }

  static get AVAILABLE_DELIVERY_TIP_LOCATIONS() {
    // given location is for test purpose
    return ['testLocation'];
  }
}
