import styled from 'styled-components';

const Toolbar = styled.div`
  font-family: ${props => props.theme.fontFamily};
  font-size: 1em;
  width: 100%;
  min-width: 100%;
  height: 64px;
  position: sticky;
  top: 0;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
  z-index: 100;
  background-color: white;
`;

export default Toolbar;
