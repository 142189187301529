import { of } from 'rxjs/observable/of';
import { selectApiKey, types as guestTypes } from '../reducers/guest';
import { actions, types as paymentTypes } from '../reducers/payment';
import {
  getPaymentiFrameParameters,
  addNewCardApi,
  deleteCardApi,
  getVaultedCardsApi,
} from '../services/paymentApi';

export const GetPaymentiFrame = (action$, store) =>
  action$.ofType(paymentTypes.GET_PAYMENT_FRAME).switchMap(() => {
    const state = store.getState();
    const apiKey = selectApiKey(state);
    return getPaymentiFrameParameters(apiKey)
      .map(actions.getPaymentFrameSuccess)
      .catch((err) => of(actions.getPaymentFrameFailure(err)));
  });

export const AddNewCard = (action$, store) =>
  action$.ofType(paymentTypes.ADD_CARD).switchMap((action) => {
    const state = store.getState();
    const apiKey = selectApiKey(state);
    return addNewCardApi(apiKey, action.paymentMethodRecord)
      .map(actions.addCardSuccess)
      .catch((err) => of(actions.addCardFailure(err)));
  });

export const GetVaultedCards = (action$, store) =>
  action$.ofType(guestTypes.GET_GUEST_PROFILE_SUCCESS).switchMap(() => {
    const state = store.getState();
    const apiKey = selectApiKey(state);
    return getVaultedCardsApi(apiKey)
      .map((res) => actions.getVaultedCardsSuccess(res))
      .catch((err) => of(actions.getVaultedCardsFailure(err)));
  });

export const DeleteAccount = (action$, store) =>
  action$.ofType(paymentTypes.DELETE_CARD).switchMap(({ card }) => {
    const state = store.getState();
    const apiKey = selectApiKey(state);
    return deleteCardApi(card.id, apiKey)
      .map((res) => actions.deleteCardSuccess(res))
      .catch((err) => of(actions.deleteCardFailure(err)));
  });

export default [GetPaymentiFrame, AddNewCard, GetVaultedCards, DeleteAccount];
