import PropTypes from 'prop-types';
import TopHero from './TopHero';
import MainHeader from './MainHeader';
import Toolbar from '../Toolbar/Toolbar';

function Header({ location, userName }) {
  return (
    <>
      <Toolbar><MainHeader locationName={location} userName={userName} /></Toolbar>
      <TopHero title="Catering" />
    </>
  );
}

Header.propTypes = {
  location: PropTypes.string.isRequired,
  userName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
};

export default Header;
