import { fromPromise } from 'rxjs/observable/fromPromise';
import { of } from 'rxjs/observable/of';
import { push } from 'connected-react-router';
import { actions, selectApiKey, types } from '../reducers/guest';
import { convertGuestToCfaOne, getGuestOAuth, getGuestProfile } from '../services/guestApi';
import { getHash } from '../util/location';
import getValidatedSessionAndIp from '../util/getValidatedSessionAndIp';
import { startSession } from '../services/bugsnag';

export const GetGuestOAuthSession = (action$) =>
  action$
    .ofType(types.GET_GUEST_SESSION)
    .map(getHash)
    .switchMap((hash) => {
      // TODO consider getting this through state so we can remove hash from url
      if (!hash.id_token || hash.id_token.length === 0) {
        return of(actions.noHashError());
      }
      const token = hash.id_token;
      return getGuestOAuth(token)
        .map(actions.getGuestOauthSessionSuccess)
        .catch((err) => of(actions.getGuestOauthSessionFailure(err)));
    });

export const GetGuestProfile = (actions$, store) =>
  actions$.ofType(types.GET_GUEST_SESSION_SUCCESS).switchMap(() => {
    const state = store.getState();
    const apiKey = selectApiKey(state);
    return getGuestProfile(apiKey)
      .map((res) => actions.getGuestProfileSuccess(res))
      .catch((err) => of(actions.getGuestProfileFailure(err)));
  });

// after profile successfully loaded we have enough into to start Bugsnag session
export const GetGuestProfileSuccess = (actions$) =>
  actions$.ofType(types.GET_GUEST_PROFILE_SUCCESS).switchMap(() => {
    startSession();
    return of({ profile: {}, type: '' });
  });

export const ConvertGuestToOneCustomer = (actions$, store) =>
  actions$.ofType(types.CONVERT_GUEST).switchMap((action) => {
    const state = store.getState();
    const apiKey = selectApiKey(state);
    const { password } = action;
    return fromPromise(getValidatedSessionAndIp())
      .switchMap(({ sessionId, ipAddress }) =>
        convertGuestToCfaOne({
          apiKey,
          password,
          sessionId,
          ipAddress,
        }),
      )
      .map(actions.convertGuestSuccess)
      .catch((err) => of(actions.convertGuestFailure(err)));
  });

export const ConvertGuestSuccess = (action$) =>
  action$
    .ofType(types.CONVERT_GUEST_SUCCESS)
    .map(() => push(`/payment/#id_token=${getHash().id_token}`));

export default [
  GetGuestOAuthSession,
  GetGuestProfile,
  GetGuestProfileSuccess,
  ConvertGuestToOneCustomer,
  ConvertGuestSuccess,
];
